export enum QuestionValueType {
  Boolean = "boolean",
  Text = "text",
  File = "file",
  MultipleChoice = "multiplechoice",
}

interface BaseQuestionType {
  id: string;
  type: QuestionValueType;
}

export interface BooleanQuestionType extends BaseQuestionType {
  type: QuestionValueType.Boolean;
}

export interface TextQuestionType extends BaseQuestionType {
  type: QuestionValueType.Text;
  displayIfQuestionIdIsTrue?: BooleanQuestionType["id"];
}

export interface FileQuestionType extends BaseQuestionType {
  type: QuestionValueType.File;
  displayIfQuestionIdIsTrue?: BooleanQuestionType["id"];
}

export interface MultipleChoiceQuestionType extends BaseQuestionType {
  type: QuestionValueType.MultipleChoice;
  displayIfQuestionIdIsTrue?: MultipleChoiceQuestionType["id"];
}

export type QuestionType = {
  description: string;
  displayIfQuestionIdIsTrue?: string | unknown;
} & (BooleanQuestionType | TextQuestionType | FileQuestionType | MultipleChoiceQuestionType);
