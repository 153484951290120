var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isPdfType ? _c('iframe', {
    staticClass: "pdfIframe",
    attrs: {
      "src": _vm.filePreview.source,
      "type": "application/pdf",
      "width": "100%",
      "height": "100%"
    }
  }) : _c('div', {
    staticClass: "image-content"
  }, [_c('div', {
    staticClass: "image-container"
  }, [_c('div', {
    staticClass: "image-wrapper"
  }, [_c('img', {
    style: {
      width: "".concat(_vm.imageWidth, "%"),
      height: "".concat(_vm.imageHeight, "%"),
      transform: "rotate(".concat(_vm.imageRotation, "deg)")
    },
    attrs: {
      "src": _vm.filePreview.source,
      "alt": _vm.filePreview.name
    }
  })])])]), !_vm.isPdfType ? _c('div', {
    staticClass: "button-container"
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "large": ""
          },
          on: {
            "click": function click() {
              return _vm.rotate(false);
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" mdi-rotate-left ")])], 1)];
      }
    }], null, false, 1782807139)
  }, [_c('span', [_vm._v("Roter 90° til venstre")])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "large": "",
            "disabled": _vm.imageHeight >= 200
          },
          on: {
            "click": _vm.zoomIn
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" mdi-magnify-plus-outline ")])], 1)];
      }
    }], null, false, 3529294365)
  }, [_c('span', [_vm._v("Zoom inn")])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
            attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "large": "",
            "disabled": _vm.imageHeight <= 50
          },
          on: {
            "click": _vm.zoomOut
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" mdi-magnify-minus-outline ")])], 1)];
      }
    }], null, false, 300997879)
  }, [_c('span', [_vm._v("Zoom ut")])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
            attrs = _ref4.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "large": ""
          },
          on: {
            "click": function click() {
              return _vm.rotate(true);
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" mdi-rotate-right ")])], 1)];
      }
    }], null, false, 3295749587)
  }, [_c('span', [_vm._v("Roter 90° til høyre")])])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }