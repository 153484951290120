
import { ApiGetCourseParticipantCommentDto } from "@/api/generated/Api";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { formatDate, formatDateTime } from "@/shared/helpers/dateHelpers";
import { PropType, defineComponent, ref } from "@vue/composition-api";
import * as DOMPurify from "dompurify";

export default defineComponent({
  name: "ChatLog",
  emits: ["updateGuestUserVisibilityCollection"],
  props: {
    comments: {
      type: Array as PropType<ApiGetCourseParticipantCommentDto[]>,
      required: true,
    },
    currentUserId: {
      type: Number,
      required: true,
    },
  },

  /* eslint no-empty-pattern: "error"*/
  setup(_, { emit }) {
    const editingGuestUserVisibility = ref(false);
    const purify = (comment: string | null | undefined) => DOMPurify.sanitize(comment || "");

    const addToChangeVisibilityCollection = (id: number) => {
      emit("updateGuestUserVisibilityCollection", id);
    };

    const editGuestUserVisibility = () => {
      editingGuestUserVisibility.value = !editingGuestUserVisibility.value;
    };

    return {
      formatDate,
      purify,
      formatDateTime,
      editGuestUserVisibility,
      editingGuestUserVisibility,
      addToChangeVisibilityCollection,
      hasMemberOrgAccess,
    };
  },
});
