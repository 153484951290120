var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline || _vm.modalBaseData.modalHeadline,
      "modalType": _vm.modalBaseData.modalType
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "data-cy": "cancelButton",
            "color": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.cancelButtonText) + " ")]), _c('v-btn', {
          attrs: {
            "data-cy": "submitButton",
            "color": "error",
            "disabled": _vm.disableSubmit,
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('submit', _vm.modalBaseData);
            }
          }
        }, [_vm._v(_vm._s(_vm.submitButtonText))])];
      },
      proxy: true
    }])
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }