var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": "Last opp fil",
      "modalType": _vm.modalType,
      "editable": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-file-input', {
    attrs: {
      "rules": _vm.validateNotEmpty,
      "show-size": "",
      "counter": "",
      "chips": "",
      "accept": ".pdf,.png,.jpg,.jpeg,.xls,.xlsx",
      "label": "Velg fil"
    },
    model: {
      value: _vm.fileData.file,
      callback: function callback($$v) {
        _vm.$set(_vm.fileData, "file", $$v);
      },
      expression: "fileData.file"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Velg kategori",
      "items": _vm.courseFileCategories,
      "item-text": "text",
      "item-value": "value",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.fileData.selectedCategory,
      callback: function callback($$v) {
        _vm.$set(_vm.fileData, "selectedCategory", $$v);
      },
      expression: "fileData.selectedCategory"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Beskrivelse",
      "counter": "500",
      "rules": [_vm.validateMaxLength()]
    },
    model: {
      value: _vm.fileData.fileDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.fileData, "fileDescription", $$v);
      },
      expression: "fileData.fileDescription"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }