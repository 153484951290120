
import BaseCard from "@/components/shared/BaseCard.vue";
import { FilePreviewData } from "@/shared/helpers/fileViewHelpers";
import { PropType, defineComponent } from "@vue/composition-api";
import FilePreview from "./FilePreview.vue";

export default defineComponent({
  name: "CourseViewFileModal",
  components: { BaseCard, FilePreview },
  emits: ["close"],
  props: {
    file: {
      type: Object as PropType<FilePreviewData>,
      required: true,
    },
  },
});
