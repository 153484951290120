export enum CourseParticipantStatus {
  Interested = "interessert",
  Applied = "søkt",
  Approved = "godkjent",
  SignedUp = "påmeldt",
  WaitingList = "venteliste",
  Denied = "avslått",
  Unsubscribed = "avmeldt",
  Qualified = "kvalifisert",
  LeaveOfAbsence = "permisjon",
  Finished = "fullført",
  PreApproved = "forhåndsgodkjent",
}
