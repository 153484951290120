var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }, [_c('CourseEditAttendanceModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "attendanceParticipant": _vm.modalData.existingItem
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      },
      "updateCourseParticipant": _vm.registerSingleAttendance
    }
  })], 1) : _vm._e(), _c('BaseTableFiltered', {
    attrs: {
      "item-key": "scheduleStart",
      "data-cy": "attendanceParticipantTable",
      "headers": _vm.headers,
      "items": _vm.attendance,
      "items-per-page": 50,
      "search": _vm.search,
      "show-select": !_vm.hasMemberOrgAccess
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light align-self"
        }, [_vm._v("Fremmøte")]), _c('v-spacer'), _c('v-text-field', {
          staticClass: "pa-0 pr-2 ma-0",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _vm.showRegisterButton ? _c('v-btn', {
          attrs: {
            "color": "primary",
            "data-cy": "batchRegisterAttendance"
          },
          on: {
            "click": _vm.batchRegisterAttendance
          }
        }, [_vm._v(" Registrer fremmøte ")]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.noOfHours",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.isRegistered ? item.noOfHours || "Ikke møtt" : item.noOfHours || "Avventer registrering") + " ")];
      }
    }, {
      key: "item.isRegistered",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value ? "Registrert" : "Ikke registrert") + " ")];
      }
    }, {
      key: "item.scheduleStart",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(_vm.formatDateTime(value)) + " ")];
      }
    }, !_vm.hasMemberOrgAccess ? {
      key: "actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "editAttendanceParticipant"
                },
                on: {
                  "click": function click($event) {
                    return _vm.openAttendanceParticipantModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger fremmøte")])])];
      }
    } : null], null, true),
    model: {
      value: _vm.selectedAttendances,
      callback: function callback($$v) {
        _vm.selectedAttendances = $$v;
      },
      expression: "selectedAttendances"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }