
import { api } from "@/api/api";
import { ApiGetCustomerPersonAddressListDto } from "@/api/generated/Api";
import { debounceHelper } from "@/shared/debounce";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

const SEARCH_PAGE_SIZE = 20;
const QUERY_TEXT_MIN_LENGTH = 3;

export default defineComponent({
  name: "PersonSearchInput",
  props: {
    label: {
      type: String,
      required: true,
    },
    mandatory: {
      type: Boolean,
      required: false,
      default: true,
    },
    cypressId: {
      type: String,
      required: false,
    },
    value: {
      type: Number,
      required: false,
    },
  },
  emits: ["input"],
  setup(props) {
    const store = useStore<StoreState>();

    const persons = ref<ApiGetCustomerPersonAddressListDto[]>([]);
    const isLoading = ref(false);
    const itemText = (person: ApiGetCustomerPersonAddressListDto) =>
      `${person.firstName || "(fornavn mangler)"} ${person.lastName || "(etternavn mangler)"} ${
        person.email || "(epost mangler)"
      }`.trim();

    onMounted(async () => {
      if (props.value) {
        // Search all users as getCustomerPersonAddressList does not allow search by ID
        const person = (await api.user.getUserAsync(props.value)).data;
        persons.value = [{ ...person }];
      }
    });

    const searchPerson = async (queryText?: string) => {
      if (!queryText || queryText.length < QUERY_TEXT_MIN_LENGTH) {
        return;
      }

      const alreadyMatched = persons.value.some((person) => queryText === itemText(person));
      if (alreadyMatched) {
        // The update:search-input event is triggered when selecting a matching user,
        // this prevents another unnecessary search from being performed
        return;
      }

      await debounceHelper.debounce(`searchPerson-${props.cypressId || props.label}`, 500);

      // If @ is included in the search text we interpret that the user is searching for an email address
      let emailSearch = "";
      if (queryText.includes("@")) {
        emailSearch = queryText;
      }

      const [firstName, ...middleAndLastName] = queryText.split(/\s+/);
      const lastName = middleAndLastName.pop(); // Discard any middle names (not supported by backend)

      try {
        isLoading.value = true;
        persons.value = await searchPersonsByName(firstName, lastName, emailSearch);
        sortPersons(persons.value);

        if (!persons.value.length) {
          openNotification(
            store,
            NotificationItemType.Warning,
            `Fant ingen personer med fornavn/etternavn som matcher "${queryText}"`
          );
        }
      } catch (err) {
        openNotification(store, NotificationItemType.Error, "En feil oppsto ved søk etter person");
      } finally {
        isLoading.value = false;
      }
    };

    return {
      persons,
      isLoading,
      itemText,
      validateNotEmpty,
      searchPerson,
      personFilter: () => true,
      minLength: QUERY_TEXT_MIN_LENGTH,
    };
  },
});

/** Sort person array (in-place) by first name and last name */
const sortPersons = (personList: ApiGetCustomerPersonAddressListDto[]) =>
  personList.sort((a, b) => {
    const compareA = `${a.firstName ?? ""} ${a.lastName ?? ""}`.trim();
    const compareB = `${b.firstName ?? ""} ${b.lastName ?? ""}`.trim();
    return compareA.localeCompare(compareB);
  });

/** Search customer persons by first name and (optional) last name */
const searchPersonsByName = async (firstName: string, lastName?: string, email?: string) => {
  // If email is provided, search address list by email
  if (email) {
    const emailSearchResults =
      (
        await api.customer.getCustomerPersonAddressList({
          Email: email,
          IncludeInactive: false,
          PageSize: SEARCH_PAGE_SIZE,
        })
      ).data.items || [];

    return emailSearchResults;
  }

  // Query returns results if firstName matches, or if *both* firstName and
  // lastName match (only if lastName is set)
  let results =
    (
      await api.customer.getCustomerPersonAddressList({
        FirstName: firstName,
        LastName: lastName,
        IncludeInactive: false,
        PageSize: SEARCH_PAGE_SIZE,
      })
    ).data.items || [];

  if (results.length < 3 && !lastName) {
    // Only one name is provided, and the previous query by only first name
    // returned no matches - attempt query by only last name instead
    const fallbackResults =
      (
        await api.customer.getCustomerPersonAddressList({
          LastName: firstName,
          IncludeInactive: false,
          PageSize: 20,
        })
      ).data.items || [];

    if (fallbackResults.length) {
      // Return unique result
      results = [...new Set([...fallbackResults, ...results])];
    }
  }

  return results;
};
