
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseCard",
  setup(props, { slots }) {
    return {
      hasActionSlot: !!slots.actions,
    };
  },
});
