var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.changeStatusModalData.showModal ? _c('BaseModal', [_c('CourseChangeSigningStatusModal', {
    attrs: {
      "currentFile": _vm.changeStatusModalData.existingItem,
      "headline": _vm.changeStatusModalData.modalHeadline,
      "modalType": _vm.changeStatusModalData.modalType
    },
    on: {
      "close": function close() {
        return _vm.changeStatusModalData.showModal = false;
      },
      "submit": _vm.submitChangeSigningStatus
    }
  })], 1) : _vm._e(), _vm.newFileModalData.showModal ? _c('BaseModal', [_c('CourseAddFileModal', {
    attrs: {
      "participantId": _vm.selectedUserId,
      "courseId": _vm.courseId,
      "includeSinging": ""
    },
    on: {
      "close": function close() {
        return _vm.newFileModalData.showModal = false;
      },
      "submit": _vm.submitCreateFileModal
    }
  })], 1) : _vm._e(), _vm.filePreviewModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1500
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      }
    }
  }, [_c('CourseViewFileModal', {
    attrs: {
      "file": _vm.getFilePreviewData(_vm.filePreviewModalData.existingItem)
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      }
    }
  })], 1) : _vm._e(), _vm.sendToSigningModalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close() {
        return _vm.sendToSigningModalData.showModal = false;
      }
    }
  }, [_c('SendDocumentToSigningModal', {
    attrs: {
      "selectedUserId": _vm.selectedUserId,
      "file": _vm.getFilePreviewData(_vm.sendToSigningModalData.existingItem),
      "modalType": _vm.sendToSigningModalData.modalType
    },
    on: {
      "close": function close() {
        return _vm.sendToSigningModalData.showModal = false;
      }
    }
  })], 1) : _vm._e(), _vm.deleteModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.deleteModalData
    },
    on: {
      "close": function close($event) {
        _vm.deleteModalData.showModal = false;
      },
      "submit": function submit() {
        return _vm.deleteFile(_vm.deleteModalData.existingItem);
      }
    }
  }, [_vm._v(" Er du sikker på at du vil slette dette dokumentet? ")])], 1) : _vm._e(), _c('v-form', [_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.courseFiles,
      "search": _vm.search,
      "no-data-text": "Ingen filer"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center",
          staticStyle: {
            "align-self": "center"
          },
          attrs: {
            "data-cy": "participantsHeader"
          }
        }, [_vm._v(" Dokumenter ")]), _c('v-spacer'), _c('v-text-field', {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('BaseTooltipIconButton', {
          attrs: {
            "color": "primary",
            "btnText": "Legg til fil",
            "icon": "mdi-plus"
          },
          on: {
            "handleClick": _vm.showCreateFileModal
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.showFilePreviewModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-eye ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis dokument")])]), !_vm.isCourseDone ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.showDeleteFileModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-delete ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett dokument")])]) : _vm._e()];
      }
    }, {
      key: "item.description",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(_vm._s(value && value.length > 20 ? "".concat(value.substring(0, 20), "...") : value))])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(value))])])];
      }
    }, {
      key: "item.inserted",
      fn: function fn(_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(_vm.formatDateTime(value)) + " ")];
      }
    }], null, true)
  }, [_c('v-spacer')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }