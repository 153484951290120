
import { api } from "@/api/api";
import {
  ApiCreateCourseParticipantCommentDto,
  ApiGetCourseDefaultOptionDto,
  ApiGetCourseParticipantCommentDto,
  ApiGetCustomerOrganizationAddressListDto,
  ApiGetGuestEmployeewithMemberOrganizationDto,
  ApiGetHierarchicalMemberOrganizationDto,
  ApiGetMemberOrganizationFeatureSettingDto,
  ApiInt32IdDto,
  ApiUpsertCourseParticipantDto,
} from "@/api/generated/Api";
import TheMemberOrganizaionModalTreeHierarchy from "@/components/administration/member-organizations/TheMemberOrganizaionModalTreeHierarchy.vue";
import CustomerOrganizationSearchInput from "@/components/common/CustomerOrganizationInput.vue";
import PersonSearchInput from "@/components/common/PersonSearchInput.vue";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { DashboardUser } from "@/shared/constants/dashboardUser";
import { CourseParticipantStatus } from "@/shared/enums/CourseParticipantStatus.enum";
import { ParticipantRoles } from "@/shared/enums/ParticipantRoles.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { hasMemberOrgAccess, useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { isVocationalSchool as useIsVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { required, validateMaxLength, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { PropType, Ref, computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline, // @ts-ignore
} from "tiptap-vuetify";
import ChatLog from "./ChatLog.vue";
import { ActiveUser, MemberOrganization } from "./ParticipantsTable.vue";
import { HierarchyLevel, checkMemberOrgLevel } from "@/shared/helpers/memberOrgHelpers";

const initialApplicant: () => ApiUpsertCourseParticipantDto = () => ({
  userId: 0,
  status: "",
  roleName: ParticipantRoles.Student,
  requiresElectronicInvoice: false,
  memberOrganizationId: null,
});

const initialComment: () => ApiCreateCourseParticipantCommentDto = () => ({
  comment: "",
  isGuestUserVisible: false,
});

export default defineComponent({
  name: "UpsertParticipantModal",
  emits: ["updateParticipantsTable", "close"],
  components: {
    BaseModalForm,
    ChatLog,
    TiptapVuetify,
    PersonSearchInput,
    CustomerOrganizationSearchInput,
    TheMemberOrganizaionModalTreeHierarchy,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    memberOrganizations: {
      type: Array as PropType<MemberOrganization[]>,
      required: true,
    },
    activeUsers: {
      type: Array as PropType<ActiveUser[]>,
      required: true,
    },
    courseDefaultOptions: {
      type: Object as PropType<ApiGetCourseDefaultOptionDto>,
      required: true,
    },
    isCourseDone: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedUserId: {
      type: Number,
      required: true,
    },
    currentCourseId: {
      type: Number,
      required: true,
    },
    chosenMemberOrgId: {
      type: Number,
      required: true,
    },
    subOrganizerId: {
      type: Number,
      required: false,
    },
    activeFeatureSettings: {
      type: Array as PropType<ApiGetMemberOrganizationFeatureSettingDto[]>,
      required: true,
    },
  },
  setup(
    {
      type,
      courseDefaultOptions,
      currentCourseId,
      selectedUserId,
      chosenMemberOrgId,
      subOrganizerId,
      activeFeatureSettings,
    },
    { emit, refs }
  ) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const restrictedAccessApi = useRestrictedAccessApi();
    const applicantData: Ref<ApiUpsertCourseParticipantDto> = ref(initialApplicant());
    const isVocationalSchool = computed(() => useIsVocationalSchool(store.state.plans.studyplan.mainCourseId));
    const isInvoiceAndPurchaserEqual = ref(true);
    const useParticipantAsInvoice = ref(true);
    const canEditName = ref(true);
    const participantComments = ref<ApiGetCourseParticipantCommentDto[]>([]);
    const createdComment = ref<ApiCreateCourseParticipantCommentDto>(initialComment());
    const currentUserId = ref(window.sessionStorage.getItem(DashboardUser.USER_ID) || 0);
    const editingGuestUserVisibility = ref(false);
    const guestUserVisibilityCollection = ref<ApiInt32IdDto[]>([]);
    const customerOrgSearchInput = ref<any>();
    let lastTime = 0;
    const organizations = ref<ApiGetCustomerOrganizationAddressListDto[]>([]);
    const QUERY_TEXT_MIN_LENGTH = 3;
    const isDisabled = ref(false);
    const entireMemberOrgHierarchi = ref<ApiGetHierarchicalMemberOrganizationDto[]>([]);
    const chosenMemberOrgs = ref<any[]>([]);
    const hierarchyLevel = ref<string>("");
    const courseParticipantStatuses = ref<string[]>([]);
    const hasSubOrganizer = ref<boolean>(false);
    const hasPreApprove = ref<boolean>(false);
    const currentGuestUser = ref<ApiGetGuestEmployeewithMemberOrganizationDto>();
    const isGuestUserSubOrganizer = ref<boolean>(false);

    // Check if user is a part of subOrganizer, if so they can change the status from preapproved to approved etc
    const getCurrentUser = async () => {
      currentGuestUser.value = (await api.guestside.getGuestUserCurrentGuestEmployeeAsync()).data;
      await checkIfUserCanSetApprove();
    };

    const checkIfUserCanSetApprove = async () => {
      if (!currentGuestUser.value) {
        return;
      }

      if (currentGuestUser.value.memberOrganization?.id === subOrganizerId) {
        isGuestUserSubOrganizer.value = true;
      }
    };

    const filterStatuses = () => {
      const statuses: string[] = setParticipantStatuses() || [];

      // This logic does not apply to non guestuser
      if (!hasMemberOrgAccess) {
        return;
      }

      // Er du underarrangør på any nivå, kan du endre status fra forhändsgodkjent til godkjent, venteliste eller avslätt.
      if (
        isGuestUserSubOrganizer.value &&
        (hierarchyLevel.value === HierarchyLevel.LOCAL_OFFICE ||
          hierarchyLevel.value === HierarchyLevel.REGIONAL_OFFICE ||
          hierarchyLevel.value === HierarchyLevel.MAIN_OFFICE)
      ) {
        courseParticipantStatuses.value = statuses.filter(
          (x) =>
            x === CourseParticipantStatus.Approved ||
            x === CourseParticipantStatus.Applied ||
            x === CourseParticipantStatus.WaitingList ||
            x === CourseParticipantStatus.Denied
        );
      }

      // Er du på 2.1.1 og ikke underarranger - kan du redigere alle sekere knyttet til den adelingen fra sekt til forhändsgodkjent, venteliste eller avslätt.
      if (subOrganizerId && !isGuestUserSubOrganizer.value && hierarchyLevel.value === HierarchyLevel.LOCAL_OFFICE) {
        courseParticipantStatuses.value = statuses.filter(
          (x) =>
            x === CourseParticipantStatus.Applied ||
            x === CourseParticipantStatus.WaitingList ||
            x === CourseParticipantStatus.Denied ||
            x === CourseParticipantStatus.PreApproved
        );
      }

      if (!subOrganizerId && hierarchyLevel.value === HierarchyLevel.LOCAL_OFFICE) {
        courseParticipantStatuses.value = statuses.filter(
          (x) =>
            x === CourseParticipantStatus.Applied ||
            x === CourseParticipantStatus.WaitingList ||
            x === CourseParticipantStatus.Denied ||
            x === CourseParticipantStatus.PreApproved
        );
      }

      // Pà kurs hvor medlemsorg ikke arrangerer (mangler underarranger) kan du endre fra sekt til godkjent, venteliste eller avslätt.
      if (!subOrganizerId) {
        courseParticipantStatuses.value = statuses.filter(
          (x) =>
            x === CourseParticipantStatus.Applied ||
            x === CourseParticipantStatus.Approved ||
            x === CourseParticipantStatus.WaitingList ||
            x === CourseParticipantStatus.Denied
        );
      }
    };

    const checkFeatureSettings = (id: number) => {
      if (!id) {
        return;
      }

      const memberOrganization = activeFeatureSettings.find((x) => x.organizationId === id);

      if (memberOrganization?.usePreApprovalForCourses) {
        hasPreApprove.value = true;
      }

      if (memberOrganization?.canUseSubOrganizer || subOrganizerId) {
        hasSubOrganizer.value = true;
      }
    };

    const getEntireMemberOrgHierarchi = async () => {
      // Is Guestuser
      if (hasMemberOrgAccess) {
        entireMemberOrgHierarchi.value = (await api.guestside.getHierarchyOfAllOrganizationsGuestUser()).data;
      }

      // Not Guestuser
      if (!hasMemberOrgAccess) {
        entireMemberOrgHierarchi.value = (await api.organization.getHierarchyOfAllOrganizations()).data;
      }
    };

    const setMemberOrg = async (value: ApiGetHierarchicalMemberOrganizationDto) => {
      const chosenMemberOrg = {
        id: value.id || 0,
        name: value.name || "Fant ikke",
      };

      checkFeatureSettings(value.id);
      hierarchyLevel.value = (await checkMemberOrgLevel(value.id)) || HierarchyLevel.MAIN_OFFICE;
      filterStatuses();

      chosenMemberOrgs.value.push(chosenMemberOrg);
      applicantData.value.memberOrganizationId = value.id;
    };

    const fetchOrganizations = async (query: any) => {
      if (!query) {
        return;
      }

      organizations.value = (await api.customer.getCustomerOrganizationAddressList(query)).data.items || [];

      if (!organizations.value.length) {
        openNotification(store, NotificationItemType.Warning, `Fant ingen organisasjoner på søket`);
      }
    };

    const searchCustomerOrg = async (queryText: any) => {
      if (!queryText || queryText.length < QUERY_TEXT_MIN_LENGTH) {
        return;
      }

      const now = new Date().getTime();
      if (now - lastTime < 1000) {
        return;
      }
      lastTime = now;

      try {
        await fetchOrganizations({ Name: queryText });
      } catch (error) {
        openNotification(store, NotificationItemType.Error, "En feil oppsto ved søk etter kundeorganisasjon");
      }
    };

    const updateGuestUserVisibilityCollection = (id: number): void => {
      const commentIds = guestUserVisibilityCollection.value.map((x: any) => x.id);

      if (commentIds.includes(id)) {
        guestUserVisibilityCollection.value.splice(commentIds.indexOf(id), 1);
        return;
      }

      guestUserVisibilityCollection.value.push({ id });
    };

    const changeGuestUserVisibility = () => {
      if (!guestUserVisibilityCollection.value.length) {
        return;
      }

      api.course.changeCourseParticipantsCommentGuestUserVisibilityAsync(
        currentCourseId,
        guestUserVisibilityCollection.value
      );
    };

    const getParticitpantComments = async () => {
      if (!hasMemberOrgAccess) {
        participantComments.value = (
          await api.course.getCourseParticipantCommentsAsync(currentCourseId, selectedUserId)
        ).data.reverse();
      }

      if (hasMemberOrgAccess) {
        participantComments.value = (
          await api.guestside.getGuestUserCourseParticipantCommentsAsync(currentCourseId, selectedUserId)
        ).data.reverse();
      }
    };

    const createComment = async () => {
      if (!createdComment.value.comment) {
        return;
      }

      if (hasMemberOrgAccess) {
        createdComment.value.isGuestUserVisible = true;
        await api.guestside.createGuestUserCourseParticipantCommentAsync(
          currentCourseId,
          applicantData.value.userId,
          createdComment.value
        );
      }

      if (!hasMemberOrgAccess) {
        await api.course.createCourseParticipantCommentAsync(
          currentCourseId,
          applicantData.value.userId,
          createdComment.value
        );
      }

      openNotification(store, NotificationItemType.Success, "Kommentar er lagt til");
    };

    const setInitMemberOrg = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (chosenMemberOrgId) {
          let chosenMemberOrg;

          if (!hasMemberOrgAccess) {
            chosenMemberOrg = (await api.organization.getMemberOrganizationAsync(chosenMemberOrgId)).data;
          }

          if (hasMemberOrgAccess) {
            const response = (await api.guestside.getGuestUserMemberOrganizations()).data;
            chosenMemberOrg = response.find((x) => x.id === chosenMemberOrgId);
          }

          if (chosenMemberOrg) {
            setMemberOrg(chosenMemberOrg);
          }
        }
      });
    };

    const setParticipantStatuses = () => {
      if (hasMemberOrgAccess) {
        return courseDefaultOptions.memberOrganizationParticipantStatuses;
      }
      if (isVocationalSchool.value) {
        return courseDefaultOptions.vocationalSchoolParticipantStatuses;
      }
      return courseDefaultOptions.participantStatuses;
    };

    const getActiveUserText = (item: ActiveUser) =>
      `${item.firstName} ${item.lastName} - ${item.email} - ${item.birthDate}`;

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        courseParticipantStatuses.value = setParticipantStatuses() || [];
        getEntireMemberOrgHierarchi();

        if (hasMemberOrgAccess) {
          await getCurrentUser();
        }

        if (type === ModalType.Add) {
          return;
        }

        if (type === ModalType.Edit) {
          canEditName.value = false;
        }

        setInitMemberOrg();

        getParticitpantComments();

        const {
          userId,
          status,
          roleName,
          requiresElectronicInvoice,
          memberOrganizationId,
          invoiceRecipient,
          purchaser,
        } = store.state.courseParticipants.person;

        // If participant status is anything but "søkt", they can't edit
        if (hasMemberOrgAccess) {
          if (status !== CourseParticipantStatus.Applied && status !== CourseParticipantStatus.PreApproved) {
            isDisabled.value = true;
          }

          if (isGuestUserSubOrganizer.value && status !== CourseParticipantStatus.Approved) {
            isDisabled.value = false;
          }
        }

        applicantData.value = {
          userId,
          status,
          roleName,
          requiresElectronicInvoice,
          memberOrganizationId: memberOrganizationId || null,
          purchaserId: purchaser?.customerId || null,
          invoiceRecipientId: invoiceRecipient?.customerId || null,
        };

        if (applicantData.value.purchaserId !== applicantData.value.invoiceRecipientId) {
          isInvoiceAndPurchaserEqual.value = false;
        }

        if (applicantData.value.invoiceRecipientId && applicantData.value.invoiceRecipientId > 0) {
          useParticipantAsInvoice.value = false;
        }
      });
    });

    const onSubmit = async () => {
      const isValid = getValidatableRef(refs.form)?.validate();
      if (!isValid) {
        return;
      }

      globalLoadingWrapper({ blocking: true }, async () => {
        createComment();
        changeGuestUserVisibility();
        if (isInvoiceAndPurchaserEqual.value) {
          applicantData.value.invoiceRecipientId = applicantData.value.purchaserId;
        }

        await restrictedAccessApi.upsertCourseParticipant(
          +route.params.id,
          {
            ...applicantData.value,
            purchaserId: useParticipantAsInvoice.value ? null : applicantData.value.purchaserId,
            invoiceRecipientId: useParticipantAsInvoice.value ? null : applicantData.value.invoiceRecipientId,
          },
          applicantData.value.userId
        );

        openNotification(
          store,
          NotificationItemType.Success,
          type === ModalType.Edit ? "Bruker oppdatert" : "Bruker lagt til"
        );

        store.commit("courseParticipants/SET_PERSON", {
          userId: applicantData.value.userId,
          status: applicantData.value.status,
          roleName: applicantData.value.roleName,
          requiresElectronicInvoice: applicantData.value.requiresElectronicInvoice,
          memberOrganizationId: applicantData.value.memberOrganizationId,
          purchaser: { customerId: applicantData.value.purchaserId },
          invoiceRecipient: { customerId: applicantData.value.invoiceRecipientId },
        });

        emit("updateParticipantsTable");
        emit("close");
      });
    };

    return {
      ModalType,
      applicantData,
      validateNotEmpty,
      validateMaxLength,
      chosenMemberOrgs,
      entireMemberOrgHierarchi,
      required,
      onSubmit,
      isVocationalSchool,
      hasMemberOrgAccess,
      courseParticipantStatuses,
      getActiveUserText,
      isInvoiceAndPurchaserEqual,
      useParticipantAsInvoice,
      canEditName,
      createdComment,
      participantComments,
      currentUserId,
      changeGuestUserVisibility,
      editingGuestUserVisibility,
      guestUserVisibilityCollection,
      updateGuestUserVisibilityCollection,
      updatePurchaserId: (orgId: number) => (applicantData.value.purchaserId = orgId),
      updateInvoiceRecipientId: (orgId: number) => (applicantData.value.invoiceRecipientId = orgId),
      customerOrgSearchInput,
      searchCustomerOrg,
      organizations,
      minLength: QUERY_TEXT_MIN_LENGTH,
      isDisabled,
      setMemberOrg,
    };
  },

  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
});
