var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseCard', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.file.name) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "px-6",
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_vm._v(" Lukk ")])];
      },
      proxy: true
    }])
  }, [[_c('FilePreview', {
    attrs: {
      "file": _vm.file
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }