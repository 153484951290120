var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', {
    attrs: {
      "disabled": _vm.disable,
      "label": _vm.label,
      "items": _vm.organizations,
      "loading": _vm.loading,
      "item-text": "name",
      "item-value": "id",
      "no-data-text": "Skriv minst ".concat(_vm.minLength, " bokstaver for \xE5 s\xF8ke")
    },
    on: {
      "update:search-input": _vm.searchCustomerOrg,
      "change": _vm.updateId
    },
    model: {
      value: _vm.selectedOrgId,
      callback: function callback($$v) {
        _vm.selectedOrgId = $$v;
      },
      expression: "selectedOrgId"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }