var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType,
      "editable": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('p', [_vm._v("Her kan du manuelt endre status på signerning av dette dokumentet.")]), _c('v-select', {
    attrs: {
      "label": "Velg ny signeringsstatus",
      "items": _vm.selectableSigningStatuses,
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.newSigningStatus,
      callback: function callback($$v) {
        _vm.newSigningStatus = $$v;
      },
      expression: "newSigningStatus"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }