
import { binaryApi } from "@/api/api";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { FilePreviewData, getInitialFilePreview } from "@/shared/helpers/fileViewHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { PropType, computed, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "FilePreview",
  emits: ["close"],
  props: {
    file: {
      type: Object as PropType<FilePreviewData>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const PDF_MIME_TYPE = "pdf";
    const filePreview = ref(getInitialFilePreview());
    const isPdfType = computed(
      () => props.file.mimeType?.includes(PDF_MIME_TYPE) || props.file?.name?.toLocaleLowerCase().endsWith("pdf")
    );
    const imageHeight = ref(50);
    const imageWidth = ref(50);
    const imageRotation = ref(0);

    const setFilePreview = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        if (!props.file.name) {
          return;
        }
        try {
          const courseFile = await binaryApi.file.downloadFileAsync(props.file.id, { format: "blob" });
          const blob = new Blob([courseFile.data], { type: courseFile.data.type });
          const url = URL.createObjectURL(blob);
          filePreview.value = {
            name: props.file.name,
            mimeType: props.file.mimeType,
            source: url,
          };
        } catch {
          openNotification(store, NotificationItemType.Error, `En feil oppsto ved visning av ${props.file.name}.`);
        }
      });
    };

    onMounted(async () => {
      await setFilePreview();
    });

    const zoomIn = () => {
      if (imageHeight.value < 200) {
        imageWidth.value += 10;
        imageHeight.value += 10;
      }
    };

    const zoomOut = () => {
      if (imageHeight.value > 50) {
        imageWidth.value -= 10;
        imageHeight.value -= 10;
      }
    };

    const rotate = (right: boolean) => {
      imageRotation.value += right ? 90 : -90;
    };

    return {
      filePreview,
      isPdfType,
      zoomIn,
      zoomOut,
      rotate,
      imageWidth,
      imageHeight,
      imageRotation,
    };
  },
});
