import countries from "i18n-iso-countries";
import nbLocale from "i18n-iso-countries/langs/nb.json";

export const getCountries = () => {
  countries.registerLocale(nbLocale);

  const countryObj = countries.getNames("nb", { select: "official" });

  return Object.entries(countryObj).map(([key, value]) => ({
    label: value,
    value: key,
  }));
};
