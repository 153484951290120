var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pa-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": 2
    }
  }, [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "dark": "",
      "color": "primary"
    }
  }, [_c('v-toolbar-title', [_vm._t("title")], 2), _c('v-spacer'), _vm._t("header-actions")], 2), _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm._t("default")], 2), _vm.hasActionSlot ? _c('v-divider') : _vm._e(), _vm.hasActionSlot ? _c('v-card-actions', [_c('v-spacer'), _vm._t("actions")], 2) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }