var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_vm.personalFields && _vm.personalFields.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "title pa-2 py-0 font-weight-light headerColor"
  }, [_vm._v(" Personalia: ")])]) : _vm._e(), _vm._l(_vm.personalFields, function (item, i) {
    return _c('v-col', {
      key: "".concat(i, "-pers-field"),
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "value": item.value,
        "label": item.label,
        "dataCy": "".concat(i, "-pers-field")
      }
    })], 1);
  }), _vm.employerFields && _vm.employerFields.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "title pa-2 py-0 font-weight-light headerColor"
  }, [_vm._v(" Arbeidsgiver: ")])]) : _vm._e(), _vm._l(_vm.employerFields, function (item, i) {
    return _c('v-col', {
      key: "".concat(i, "-empl-field"),
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "value": item.value,
        "label": item.label,
        "dataCy": "".concat(i, "-empl-field")
      }
    })], 1);
  }), _vm.showCostBearerSection ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "title pa-2 py-0 font-weight-light headerColor"
  }, [_vm._v(" Betalingsinformasjon " + _vm._s(_vm.showCostBearerPrivate ? "(Privat):" : "(Ikke privat):") + " ")])]) : _vm._e(), _vm.showCostBearerPrivate ? _vm._l(_vm.costBearerPrivateFields, function (item, i) {
    return _c('v-col', {
      key: "".concat(i, "-cost-bearer-private-field"),
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "value": item.value,
        "label": item.label,
        "dataCy": "".concat(i, "-cost-bearer-private-field")
      }
    })], 1);
  }) : _vm._e(), _vm.showCostBearerNonPrivate ? [_vm.applicationInfo.costBearerOrganization && !_vm.applicationInfo.costBearerOrganization.isPurchaser ? _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "subtitle-1 pa-2 py-0 headerColor"
  }, [_vm._v(" Informasjon om bestiller: ")])]) : _vm._e(), _vm._l(_vm.purchaserFields, function (item, i) {
    return _c('v-col', {
      key: "".concat(i, "-purchaser-field"),
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "value": item.value,
        "label": item.label,
        "dataCy": "".concat(i, "-purchaser-field")
      }
    })], 1);
  }), _vm.applicationInfo.costBearerOrganization && !_vm.applicationInfo.costBearerOrganization.isPurchaser ? [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "subtitle-1 pa-2 py-0 headerColor"
  }, [_vm._v(" Informasjon om fakturamottaker: ")])]), _vm._l(_vm.costBearerOrganizationFields, function (item, i) {
    return _c('v-col', {
      key: "".concat(i, "-cost-bearer-org-field"),
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "value": item.value,
        "label": item.label,
        "dataCy": "".concat(i, "-cost-bearer-org-field")
      }
    })], 1);
  })] : _vm._e()] : _vm._e(), _vm.applicationFields && _vm.applicationFields.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "title pa-2 py-0 font-weight-light headerColor"
  }, [_vm._v(" Søknad: ")])]) : _vm._e(), _vm._l(_vm.applicationFields, function (item, i) {
    return _c('v-col', {
      key: "".concat(i, "-app-field"),
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "value": item.value,
        "label": item.label,
        "dataCy": "".concat(i, "-app-field")
      }
    })], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "title pa-2 py-0 font-weight-light headerColor"
  }, [_vm._v(" Filer: ")])]), _vm.applicationInfo.files && _vm.applicationInfo.files.length ? _vm._l(_vm.applicationInfo.files, function (file, i) {
    return _c('v-col', {
      key: "".concat(i, "-file-field"),
      staticClass: "pt-0",
      attrs: {
        "data-cy": "".concat(i, "-file-field"),
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "value": file.originalFileName,
        "label": "Fil"
      }
    }), _c('BaseSheetField', {
      attrs: {
        "value": file.mimeType,
        "label": "Type"
      }
    }), _c('BaseSheetField', {
      attrs: {
        "value": file.category,
        "label": "Kategori"
      }
    }), _c('BaseSheetField', {
      attrs: {
        "value": file.description,
        "label": "Beskrivelse"
      }
    })], 1);
  }) : [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "pa-2 py-0 text-lg grey--text text--darken-1 font-weight-light"
  }, [_vm._v(" Ingen filer ")])])], _vm.visibleQuestions && _vm.visibleQuestions.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "title pa-2 py-0 font-weight-light headerColor"
  }, [_vm._v(" Tilleggspørsmål: ")])]) : _vm._e(), _vm._l(_vm.visibleQuestions, function (question, i) {
    return _c('v-col', {
      key: "".concat(i, "-question-field"),
      staticClass: "pt-0",
      attrs: {
        "data-cy": "".concat(i, "-question-field"),
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "value": question.question,
        "label": "Spørsmål"
      }
    }), _c('BaseSheetField', {
      attrs: {
        "value": question.answer,
        "label": "Svar"
      }
    })], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }