
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { Route } from "vue-router";

export default defineComponent({
  name: "BaseIconCard",
  props: {
    icon: {
      type: String,
      required: false,
    },
    headline: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object],
      required: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: "",
    },
    prependIcon: {
      type: String,
      required: false,
    },
    to: {
      type: Object as PropType<Route | string>,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    link: {
      type: Boolean,
      required: false,
      default: true,
    },
    captionAbove: {
      type: Boolean,
      required: false,
      default: false,
    },
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
    alignment: {
      type: String,
      default: "center",
    },
    fillHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["cardClick", "clickedPrependIcon"],
  setup(props, { emit }) {
    const valueType = typeof props.value;
    const valueString = computed(() => {
      if (valueType === "string" || valueType === "number") {
        return props.value;
      }
      if (valueType === "boolean") {
        return props.value ? "Ja" : "Nei";
      }
      if (props.value instanceof Object) {
        const valueObject = props.value as { name: string; description: string };
        const name: string = valueObject.name;
        const description: string = valueObject.description;
        return name ?? description;
      }
      return "-";
    });
    return {
      valueString,
      eventHandlers: props.link
        ? {
            click: () => {
              emit("cardClick");
            },
          }
        : {},
    };
  },
});
