var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType,
      "editable": ""
    },
    on: {
      "submit": function submit($event) {
        return _vm.updateContactInformation();
      },
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', {
    staticClass: "pt-5"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Fornavn og mellomnavn",
      "required": ""
    },
    model: {
      value: _vm.customerValues.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.customerValues, "firstName", $$v);
      },
      expression: "customerValues.firstName"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Etternavn",
      "required": ""
    },
    model: {
      value: _vm.customerValues.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.customerValues, "lastName", $$v);
      },
      expression: "customerValues.lastName"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [!_vm.isEditingProfile ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.dateOfBirth,
      "headline": "Fødselsdato",
      "link": false,
      "captionAbove": "",
      "outlined": "",
      "dense": "",
      "light": "",
      "prependIcon": "mdi-pencil"
    },
    on: {
      "clickedPrependIcon": _vm.editInternalProfile
    }
  }) : _c('BaseBirthDateInput', {
    attrs: {
      "birthDate": _vm.customerValues.birthDate,
      "birthYear": _vm.customerValues.birthYear && _vm.customerValues.birthYear.toString(),
      "persistent-placeholder": "",
      "outlined": ""
    },
    on: {
      "emitChanges": _vm.updateBirthDate,
      "dateChanged": _vm.updateBirthDate,
      "yearChanged": _vm.updateBirthYear
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "pt-5 pb-0",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [_c('v-phone-input', {
    attrs: {
      "outlined": "",
      "rules": _vm.validateNotEmpty,
      "country-icon-mode": "svg"
    },
    model: {
      value: _vm.customerValues.mobileNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.customerValues, "mobileNumber", $$v);
      },
      expression: "customerValues.mobileNumber"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "label": "Kjønn",
      "items": [{
        label: 'Mann',
        value: 'M'
      }, {
        label: 'Kvinne',
        value: 'K'
      }],
      "rules": _vm.validateNotEmpty,
      "item-text": "label",
      "item-value": "value"
    },
    model: {
      value: _vm.customerValues.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.customerValues, "gender", $$v);
      },
      expression: "customerValues.gender"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Fødselsnummer/d-nummer",
      "required": ""
    },
    model: {
      value: _vm.customerValues.ssno,
      callback: function callback($$v) {
        _vm.$set(_vm.customerValues, "ssno", $$v);
      },
      expression: "customerValues.ssno"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Gatenavn",
      "required": ""
    },
    model: {
      value: _vm.customerValues.postAddress1,
      callback: function callback($$v) {
        _vm.$set(_vm.customerValues, "postAddress1", $$v);
      },
      expression: "customerValues.postAddress1"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "3",
      "md": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Postnr",
      "required": ""
    },
    model: {
      value: _vm.customerValues.postZip,
      callback: function callback($$v) {
        _vm.$set(_vm.customerValues, "postZip", $$v);
      },
      expression: "customerValues.postZip"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "9",
      "md": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Poststed",
      "required": ""
    },
    model: {
      value: _vm.customerValues.postCity,
      callback: function callback($$v) {
        _vm.$set(_vm.customerValues, "postCity", $$v);
      },
      expression: "customerValues.postCity"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "5",
      "md": "5"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "type": "text",
      "items": _vm.countries,
      "item-text": "label",
      "item-value": "value",
      "outlined": "",
      "hide-details": "",
      "label": "Land",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.customerValues.postCountry,
      callback: function callback($$v) {
        _vm.$set(_vm.customerValues, "postCountry", $$v);
      },
      expression: "customerValues.postCountry"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "7",
      "md": "7"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.validateOptionalAccountNumber,
      "label": "Bankkontonummer",
      "required": ""
    },
    model: {
      value: _vm.customerValues.bankAccountNo,
      callback: function callback($$v) {
        _vm.$set(_vm.customerValues, "bankAccountNo", $$v);
      },
      expression: "customerValues.bankAccountNo"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }