var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "chat-log__container"
  }, _vm._l(_vm.comments, function (comment) {
    return _c('div', {
      key: comment.id,
      staticStyle: {
        "margin-top": "30px",
        "display": "flex",
        "flex-direction": "column"
      }
    }, [+_vm.currentUserId !== comment.insertedBy ? _c('h4', {
      staticClass: "sub__heading"
    }, [_vm._v(" " + _vm._s(comment.insertedByUserFullName) + " ")]) : _vm._e(), _c('div', {
      class: +_vm.currentUserId === comment.insertedBy ? "current-user__bubble chat__bubble" : "chat__bubble"
    }, [_c('div', {
      attrs: {
        "data-cy": "comment-".concat(comment.id)
      },
      domProps: {
        "innerHTML": _vm._s(_vm.purify(comment.comment))
      }
    }), _vm.editingGuestUserVisibility ? _c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_c('div', _vm._g(_vm._b({
            attrs: {
              "id": +_vm.currentUserId === comment.insertedBy ? "current-user-switch" : "user-switch"
            }
          }, 'div', attrs, false), on), [_c('v-switch', {
            attrs: {
              "dense": ""
            },
            on: {
              "change": function change($event) {
                return _vm.addToChangeVisibilityCollection(comment.id);
              }
            },
            model: {
              value: comment.isGuestUserVisible,
              callback: function callback($$v) {
                _vm.$set(comment, "isGuestUserVisible", $$v);
              },
              expression: "comment.isGuestUserVisible"
            }
          })], 1)];
        }
      }], null, true)
    }, [_c('div', [_vm._v(" " + _vm._s(comment.isGuestUserVisible ? "Synlig for gjestebruker" : "Ikke synlig for gjestebruker") + " ")])]) : _vm._e()], 1), _c('h6', {
      class: +_vm.currentUserId === comment.insertedBy ? "current-user-sub_heading sub__heading" : "sub__heading"
    }, [_vm._v(" " + _vm._s(_vm.formatDateTime(comment.inserted)) + " ")])]);
  }), 0), !_vm.hasMemberOrgAccess ? _c('v-btn', {
    staticClass: "mt-3",
    on: {
      "click": _vm.editGuestUserVisibility
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-eye")]), _vm._v(_vm._s(_vm.editingGuestUserVisibility ? "Stopp endring" : "Endre synlighet"))], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }