
import { api } from "@/api/api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import CourseViewFileModal from "@/components/course/details/files/CourseViewFileModal.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { FilePreviewData } from "@/shared/helpers/fileViewHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { PropType, defineComponent, ref } from "@vue/composition-api";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ApiCreateSigningFileDto } from "@/api/generated/Api";

export default defineComponent({
  name: "SendDocumentToSigningModal",
  components: { BaseModalForm, BaseModal, CourseViewFileModal },
  emits: ["close"],
  props: {
    file: {
      type: Object as PropType<FilePreviewData>,
      required: true,
    },
    sigingHeadline: {
      type: String,
      default: "Send til signering",
    },
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const signingDeadline = ref();
    const previewModalData = ref(getInitialModalData());
    const route = useRoute();

    const showFilePreviewModal = useOpenModal(ModalType.Unspecified, "", previewModalData);

    const onSubmit = async () => {
      const isValid = getValidatableRef(refs.form)?.validate();
      if (!isValid) {
        return;
      }

      const payload: ApiCreateSigningFileDto = {
        courseId: +route.params.id,
        userId: +route.params.participantId,
        fileId: props.file.id,
        signatureDeadline: null,
      };

      globalLoadingWrapper({ blocking: true }, async () => {
        api.signature.sendFileForSigning(payload);
        openNotification(store, NotificationItemType.Success, "Fil sendt til signering");
        emit("submit");
      });
    };

    return {
      onSubmit,
      signingDeadline,
      validateNotEmpty,
      previewModalData,
      showFilePreviewModal,
    };
  },
});
