var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.previewModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1500
    },
    on: {
      "close": function close() {
        return _vm.previewModalData.showModal = false;
      }
    }
  }, [_c('CourseViewFileModal', {
    attrs: {
      "file": _vm.file
    },
    on: {
      "close": function close() {
        return _vm.previewModalData.showModal = false;
      }
    }
  })], 1) : _vm._e(), _c('BaseModalForm', {
    attrs: {
      "headline": _vm.sigingHeadline,
      "modalType": _vm.modalType,
      "submitButtonText": "Send til signering",
      "editable": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('p', [_vm._v("Legg til en frist for signering før dokumentet blir sendt til deltaker for signering")]), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.showFilePreviewModal
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-paperclip")]), _vm._v(" Vis fil ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }