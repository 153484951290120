
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { DocumentSigningStatus } from "@/shared/enums/documentSigningStatus.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { PropType, defineComponent, ref } from "@vue/composition-api";
import { CourseFilesWithSigning } from "../participant/ParticipantDocuments.vue";

export default defineComponent({
  name: "CourseChangeSigningStatusModal",
  emits: ["close"],
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    currentFile: {
      type: Object as PropType<CourseFilesWithSigning>,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const newSigningStatus = ref<DocumentSigningStatus>(props.currentFile?.signingStatus as DocumentSigningStatus);
    const selectableSigningStatuses = [
      DocumentSigningStatus.Expired,
      DocumentSigningStatus.SigningComplete,
      DocumentSigningStatus.Rejected,
    ];

    const onSubmit = async () => {
      const isValid = getValidatableRef(refs.form)?.validate();
      if (!isValid) {
        return;
      }

      globalLoadingWrapper({ blocking: true }, async () => {
        openNotification(store, NotificationItemType.Success, "Signeringsstatus oppdatert.");
        emit("submit");
      });
    };

    return {
      newSigningStatus,
      selectableSigningStatuses,
      onSubmit,
      validateNotEmpty,
    };
  },
});
