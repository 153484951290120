import { ApiGetCourseRelatedFileDto } from "@/api/generated/Api";

export interface FileViewModel extends ApiGetCourseRelatedFileDto {
  insertedByFullName: string;
  participantUserFullName?: string;
  participantStatus?: string;
  participantRole?: string;
}

export interface FilePreview {
  name?: string;
  mimeType?: string | null;
  source?: string;
}

export interface FilePreviewData {
  id: number;
  name?: string | null;
  mimeType?: string | null;
}

export const getInitialFilePreview: () => FilePreview = () => ({
  name: undefined,
  mimeType: undefined,
  source: undefined,
});
