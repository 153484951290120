
import { api } from "@/api/api";
import { ApiGetCustomerOrganizationAddressListDto } from "@/api/generated/Api";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "CustomerOrganizationSearchInput",
  emits: ["updateId"],
  props: {
    disable: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    existingId: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const organizations = ref<ApiGetCustomerOrganizationAddressListDto[]>([]);
    const QUERY_TEXT_MIN_LENGTH = 3;
    const selectedOrgId = ref<number>();
    const loading = ref(false);
    let lastTime = 0;

    watch(
      props,
      () => {
        getSelectedCustomerOrg();
      },
      { deep: true }
    );

    const getSelectedCustomerOrg = async (): Promise<void> => {
      if (!props.existingId) {
        return;
      }

      // Prevent refetch if org is already in list
      if (organizations.value.some((org) => props.existingId === org.id)) {
        return;
      }

      loading.value = true;

      const fetchedCustomerOrg = (await api.customer.getCustomerOrgByIdAsync(props.existingId)).data.customer;
      selectedOrgId.value = props.existingId;
      organizations.value.push({ id: fetchedCustomerOrg.customerId, ...fetchedCustomerOrg });

      loading.value = false;
    };

    const fetchOrganizations = async (query: any) => {
      if (!query) {
        return;
      }

      organizations.value = (await api.customer.getCustomerOrganizationAddressList(query)).data.items || [];

      if (!organizations.value.length) {
        openNotification(store, NotificationItemType.Warning, `Fant ingen organisasjoner på søket`);
      }
    };

    const searchCustomerOrg = async (queryText: string): Promise<void> => {
      if (!queryText || queryText.length < QUERY_TEXT_MIN_LENGTH) {
        return;
      }

      // Prevent refetch if org is already in list
      if (organizations.value.some((org) => queryText === org.name)) {
        return;
      }

      const now = new Date().getTime();
      if (now - lastTime < 1000) {
        return;
      }
      lastTime = now;

      try {
        await fetchOrganizations({ Name: queryText });
      } catch (error) {
        openNotification(store, NotificationItemType.Error, "En feil oppsto ved søk etter kundeorganisasjon");
      }
    };

    const updateId = (id: number) => {
      emit("updateId", id);
    };

    return {
      searchCustomerOrg,
      organizations,
      minLength: QUERY_TEXT_MIN_LENGTH,
      selectedOrgId,
      updateId,
      loading,
    };
  },
});
