import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  Underline, // @ts-ignore
} from "tiptap-vuetify";

export const tiptapExtensions = [
  History,
  Blockquote,
  Link,
  Underline,
  Strike,
  Italic,
  ListItem,
  BulletList,
  OrderedList,
  [
    Heading,
    {
      options: {
        levels: [1, 2, 3],
      },
    },
  ],
  Bold,
  Code,
  HorizontalRule,
  Paragraph,
  HardBreak,
];
