
import { defineComponent, PropType, ref } from "@vue/composition-api";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
export default defineComponent({
  name: "BaseConfirmModalForm",
  components: { BaseModalForm },
  props: {
    modalBaseData: {
      type: Object as PropType<ModalBaseData>,
      required: true,
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
    headline: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const submitButtonText = ref(
      props.modalBaseData.additionalProperties?.submitButtonText || props.modalBaseData.modalType === ModalType.Delete
        ? "Slett"
        : "Bekreft"
    );
    const cancelButtonText = ref(props.modalBaseData.additionalProperties?.cancelButtonText || "Avbryt");

    return { submitButtonText, cancelButtonText };
  },
});
