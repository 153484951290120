
import { api } from "@/api/api";
import { ApiGetSigningFileDto } from "@/api/generated/Api";
import CourseAddFileModal from "@/components/course/details/files/CourseAddFileModal.vue";
import CourseChangeSigningStatusModal from "@/components/course/details/files/CourseChangeSigningStatusModal.vue";
import CourseViewFileModal from "@/components/course/details/files/CourseViewFileModal.vue";
import SendDocumentToSigningModal from "@/components/course/details/files/SendDocumentToSigningModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import { DocumentSigningStatus } from "@/shared/enums/documentSigningStatus.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { FilePreviewData } from "@/shared/helpers/fileViewHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "ParticipantSigningDocuments",
  components: {
    BaseTableFiltered,
    CourseAddFileModal,
    BaseModal,
    BaseConfirmModalForm,
    CourseViewFileModal,
    CourseChangeSigningStatusModal,
    SendDocumentToSigningModal,
  },
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();
    const course = computed(() => store.state.courses.course);
    const newFileModalData = ref(getInitialModalData());
    const changeStatusModalData = ref(getInitialModalData());
    const deleteModalData = ref(getInitialModalData());
    const filePreviewModalData = ref(getInitialModalData());
    const resendFileModalData = ref(getInitialModalData());
    const cancelFileModalData = ref(getInitialModalData());
    const sendToSigningModalData = ref(getInitialModalData());
    const changeFileStatusModalData = ref(getInitialModalData());
    const search = ref<string>();
    const selectedUserId = computed(() => +route.params.participantId);
    const courseId = computed(() => +route.params.id);
    const isCourseDone =
      course.value.status === CourseStatus.Closed || course.value.status === CourseStatus.TeachingGrantMustBeCalculated;
    const signingFiles = ref<ApiGetSigningFileDto[]>([]);

    const showDeleteFileModal = useOpenModal(ModalType.Delete, "fil", deleteModalData);
    const showFilePreviewModal = useOpenModal(ModalType.Unspecified, "", filePreviewModalData);
    const showResendFileModal = useOpenModal(ModalType.Unspecified, "", resendFileModalData);
    const showCancelFileModal = useOpenModal(ModalType.Unspecified, "", cancelFileModalData);
    const showChangeFileStatusModal = useOpenModal(ModalType.Unspecified, "", changeFileStatusModalData);

    const fetchSigningDocuments = async () => {
      const response = await api.file.getParticipantSignatureFilesCourseAdminAsync(selectedUserId.value);
      signingFiles.value = response.data;
    };

    const deleteFile = async (item: ApiGetSigningFileDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          await api.signature.deleteSigningFile(item.id);
        } catch {
          openNotification(
            store,
            NotificationItemType.Error,
            `En feil oppsto ved sletting av ${item.unsignedFileName || item.signedFileName}.`
          );
        } finally {
          deleteModalData.value.showModal = false;
          await fetchSigningDocuments();
        }
      });
    };

    const getSigningLabel = (item: ApiGetSigningFileDto) =>
      `Send ${item.unsignedFileName || item.signedFileName || "-"} til signering`;

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await fetchSigningDocuments();
      });
    });

    const statusMapper: { [key: string]: string } = {
      sending_to_middleware: "Sendt til mellomtjenesten",
      failed_sending_to_middleware: "Feilet ved sending til mellomtjenesten",
      sending_to_signicat: "Sendt til Signicat",
      failed_sending_to_signicat: "Feilet ved sending til Signicat",
      download_failed: "Nedlasting feilet",
      expired: "Utløpt",
    };

    const resendFile = async (item: ApiGetSigningFileDto) => {
      await api.signature.resendFile(item.id).then(() => {
        resendFileModalData.value.showModal = false;
        fetchSigningDocuments();
      });
    };

    const changeToSignedStatus = async (item: any) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.signature.changeToSignedStatus(item.id).then(() => {
          changeFileStatusModalData.value.showModal = false;
          fetchSigningDocuments();
        });
      });
    };

    return {
      showCancelFileModal,
      statusMapper,
      courseId,
      selectedUserId,
      headers,
      validateNotEmpty,
      deleteFile,
      showDeleteFileModal,
      showFilePreviewModal,
      showChangeFileStatusModal,
      search,
      newFileModalData,
      deleteModalData,
      filePreviewModalData,
      sendToSigningModalData,
      formatDateTime,
      isCourseDone,
      getFilePreviewData,
      getSigningLabel,
      DocumentSigningStatus,
      signingFiles,
      showResendFileModal,
      resendFileModalData,
      cancelFileModalData,
      changeFileStatusModalData,
      resendFile,
      changeToSignedStatus,
      changeStatusModalData,
    };
  },
});

const headers = [
  {
    text: "ID",
    value: "id",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
  {
    text: "Sign Status",
    value: "signStatus",
  },
  {
    text: "Unsigned File Name",
    value: "unsignedFileName",
  },
  {
    text: "Signed File ID",
    value: "signedFileId",
  },
  {
    text: "Signed File Name",
    value: "signedFileName",
  },
  {
    text: "Inserted",
    value: "inserted",
  },
];

const getFilePreviewData = (file: ApiGetSigningFileDto): FilePreviewData => ({
  id: file.unsignedFileId || file.signedFileId || 0,
  name: file.unsignedFileName || file.signedFileName,
  mimeType: "PDF",
});
