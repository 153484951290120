
import { ApiGetCourseParticipantAttendanceDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateCourseHourInput } from "@/shared/helpers/validationHelpers";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
export default defineComponent({
  name: "CourseEditAttendanceModal",
  emits: ["updateCourseParticipant", "close"],
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    attendanceParticipant: {
      type: Object as PropType<ApiGetCourseParticipantAttendanceDto>,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const attendanceInput = ref(deepCloneObject(props.attendanceParticipant));
    const handleSubmit = async () => {
      const isValid = getValidatableRef(refs.form)?.validate();
      if (!isValid) {
        return;
      }
      emit("updateCourseParticipant", attendanceInput.value);
      emit("close");
    };

    onMounted(() => {
      if (!attendanceInput.value.isRegistered && attendanceInput.value.noOfHours === 0) {
        attendanceInput.value.noOfHours = attendanceInput.value.scheduleHours;
      }
    });

    return {
      attendanceInput,
      handleSubmit,
      validateCourseHourInput,
    };
  },
});
