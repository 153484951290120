var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": "Tilbudsbrev",
      "modalType": _vm.modalType
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.generateOfferLetter
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', [!_vm.showSelectParticipant ? _c('v-select', {
    attrs: {
      "label": "Velg student",
      "items": _vm.participants,
      "item-text": "userFullName",
      "item-value": "userId"
    },
    model: {
      value: _vm.selectedParticipant,
      callback: function callback($$v) {
        _vm.selectedParticipant = $$v;
      },
      expression: "selectedParticipant"
    }
  }) : _vm._e(), _c('h4', [_vm._v("Vedtak")]), _c('tiptap-vuetify', {
    attrs: {
      "label": "Vedtaker",
      "card-props": {
        outlined: true
      },
      "extensions": _vm.tiptapExtensions
    },
    model: {
      value: _vm.offerLetter.decisionText,
      callback: function callback($$v) {
        _vm.$set(_vm.offerLetter, "decisionText", $$v);
      },
      expression: "offerLetter.decisionText"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "col": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Vis politiattest"
    },
    model: {
      value: _vm.offerLetter.showPoliceCertificate,
      callback: function callback($$v) {
        _vm.$set(_vm.offerLetter, "showPoliceCertificate", $$v);
      },
      expression: "offerLetter.showPoliceCertificate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "col": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Vis referanse"
    },
    model: {
      value: _vm.offerLetter.showReference,
      callback: function callback($$v) {
        _vm.$set(_vm.offerLetter, "showReference", $$v);
      },
      expression: "offerLetter.showReference"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('BaseDatePicker', {
    attrs: {
      "label": "Tilbudsfrist",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.offerLetter.offerDeadline,
      callback: function callback($$v) {
        _vm.$set(_vm.offerLetter, "offerDeadline", $$v);
      },
      expression: "offerLetter.offerDeadline"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }