import { ApiGetMemberOrganizationDto } from "@/api/generated/Api";
import { ref } from "@vue/composition-api";
import { useRestrictedAccessApi } from "./accessLevelApiAdapter";
import { localeSortByProperty } from "./arrayHelpers";

export interface MemberOrganization {
  id: number | null;
  name?: string | null;
}

const memberOrganizations = ref<ApiGetMemberOrganizationDto[]>([]);
const restrictedAccessApi = useRestrictedAccessApi();

export const getOrderedMemberOrganizations = async () => {
  if (memberOrganizations.value.length > 0) {
    return;
  }

  const response = (await restrictedAccessApi.getMemberOrganizations()).data.sort(localeSortByProperty("name"));

  const activeMemberOrganizations = response.filter((memberOrganization) => memberOrganization.isActive);
  // Parent/child lookup map
  // { [parentId]: MemberOrganization[] }
  const parentMemberOrganizationMap = activeMemberOrganizations.reduce<Record<number, MemberOrganization[]>>(
    (previous, { parentId, id, name }) => {
      if (parentId === null || parentId === undefined) {
        return previous;
      }
      const parentOrganization = { id, name };
      const childOrganizations = previous[parentId] ?? [];
      // lookup map with parentId as index which contains child object
      return {
        ...previous,
        [parentId]: [...childOrganizations, parentOrganization],
      };
    },
    {}
  );

  const activeParentMemberOrganizations = activeMemberOrganizations.filter(
    (memberOrganization) => !memberOrganization.parentId || memberOrganization.parentId === 1
  );

  // Ordered parent/child list of member organizations
  // [ parent_1, child_1_1, child_1_2, child_1_3, parent_2, child_2_1, ...]
  const orderedMemberOrganizations = activeParentMemberOrganizations.reduce<MemberOrganization[]>(
    (previous, { id, name }) => {
      const parentOrganization = { id, name };
      const subOrganizations = parentMemberOrganizationMap[id] ?? [];
      // map parent then add child by searching on lookup map
      return [...previous, parentOrganization, ...subOrganizations];
    },
    []
  );

  const noMemberOrganization = { id: null, name: "Ingen medlemsorganisasjon" };

  return [noMemberOrganization, ...orderedMemberOrganizations];
};
