
const initialValues: () => ApiUpdateCustomerPersonDto = () => ({
  firstName: "",
  lastName: "",
  birthDate: "",
  phoneNumber: "",
  mobileNumber: "",
  postAddress1: "",
  postZip: "",
  postCity: "",
  postCountry: "",
  gender: "",
  bankAccountNo: "",
  ssno: "",
  lastApprovedTermsOfUsage: "",
  receiveNewsletter: false,
  birthYear: 0,
});

import { api } from "@/api/api";
import { ApiUpdateCustomerPersonDto } from "@/api/generated/Api";
import BaseIconCard from "@/components/shared/BaseIconCard.vue";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import BaseBirthDateInput from "@/shared/date/BaseBirthDateInput.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { getCountries } from "@/shared/helpers/localeHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateOptionalAccountNumber, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { PropType, Ref, computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { isWithinInterval, parseISO } from "date-fns";

export default defineComponent({
  name: "EditContactInformationModal",
  components: { BaseModalForm, BaseBirthDateInput, BaseIconCard },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    existingItemModal: {
      type: Object,
    },
    existingItemId: {
      type: Number,
    },
  },
  setup(props, { emit, refs }) {
    const customerValues: Ref<ApiUpdateCustomerPersonDto> = ref(initialValues());
    const countries = getCountries();
    const customerId = ref(0);
    const store = useStore<StoreState>();
    const isEditingProfile = ref(false);

    const dateOfBirth = computed(() =>
      customerValues.value.birthDate &&
      isWithinInterval(parseISO(customerValues.value.birthDate), {
        start: new Date(1900, 1, 1),
        end: new Date(),
      })
        ? formatDate(customerValues.value.birthDate)
        : customerValues.value.birthYear?.toString().length === 4
        ? customerValues.value.birthYear
        : "Ingen fødselsdato"
    );

    const setCustomerInformation = () => {
      customerId.value = props.existingItemId as number;
      if (customerId.value === undefined) {
        return;
      }

      globalLoadingWrapper({ blocking: true }, async () => {
        const certificatePartipantData = (await api.customer.getCustomerPersonByIdAsync(+customerId.value)).data;

        const {
          phoneNumber,
          mobileNumber,
          postAddress1,
          postZip,
          postCity,
          postCountry,
          bankAccountNo,
          customer: {
            firstName,
            lastName,
            birthDate,
            birthYear,
            ssno,
            gender,
            lastApprovedTermsOfUsage,
            receiveNewsletter,
          },
        } = certificatePartipantData;

        customerValues.value = {
          firstName,
          lastName,
          birthDate,
          birthYear,
          phoneNumber,
          mobileNumber,
          postAddress1,
          postZip,
          postCity,
          postCountry,
          gender,
          bankAccountNo,
          ssno,
          lastApprovedTermsOfUsage,
          receiveNewsletter,
        };
      });
    };

    onMounted(() => {
      setCustomerInformation();
    });

    const updateContactInformation = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const isValid = getValidatableRef(refs.connectForm)?.validate();

        if (!isValid) {
          return;
        }

        const {
          firstName,
          lastName,
          birthDate,
          birthYear,
          phoneNumber,
          mobileNumber,
          postAddress1,
          postZip,
          postCity,
          postCountry,
          gender,
          bankAccountNo,
          ssno,
          lastApprovedTermsOfUsage,
          receiveNewsletter,
        } = customerValues.value;

        const payload: ApiUpdateCustomerPersonDto = {
          firstName: firstName || null,
          lastName: lastName || null,
          birthDate: birthDate || null,
          birthYear: birthYear || null,
          phoneNumber: phoneNumber || null,
          mobileNumber: mobileNumber || null,
          postAddress1: postAddress1 || null,
          postZip: postZip || null,
          postCity: postCity || null,
          postCountry: postCountry || null,
          gender: gender || null,
          bankAccountNo: bankAccountNo || null,
          ssno: ssno || null,
          lastApprovedTermsOfUsage: lastApprovedTermsOfUsage || null,
          receiveNewsletter,
        };

        await api.courseadmin.updateCustomerPersonAdminAsync(+customerId.value, payload);

        openNotification(store, NotificationItemType.Success, "Kunde er oppdatert!");
        emit("close");
      });
    };

    const updateBirthDate = (birthDate: string) => {
      customerValues.value && (customerValues.value.birthDate = birthDate);
    };

    const updateBirthYear = (year: string) => {
      customerValues.value && (customerValues.value.birthYear = +year);
    };

    return {
      updateBirthDate,
      updateBirthYear,
      customerValues,
      countries,
      validateNotEmpty,
      updateContactInformation,
      validateOptionalAccountNumber,
      isEditingProfile,
      dateOfBirth,
      editInternalProfile: () => (isEditingProfile.value = true),
    };
  },
});
