export const formatCurrency = (value: number, currency = "NOK") =>
  new Intl.NumberFormat("no", { style: "currency", currency }).format(value);

// Use "hard space" (&nbsp;) before percentage sign (https://www.korrekturavdelingen.no/mellomrom.htm)
export const formatPercentage = (value: number) => value && `${value}${"\xa0"}%`;

export const isNumber = (evt: KeyboardEvent): void => {
  const keysAllowed: string[] = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
  const keyPressed: string = evt.key;

  if (!keysAllowed.includes(keyPressed)) {
    evt.preventDefault();
  }
};
