import { api } from "@/api/api";
import { hasMemberOrgAccess } from "./accessLevelApiAdapter";

export enum HierarchyLevel {
  MAIN_OFFICE = "mainOffice",
  REGIONAL_OFFICE = "regionalOffice",
  LOCAL_OFFICE = "localOffice",
}

// Find what level in the hierarchy the memberorg is
export const checkMemberOrgLevel = async (id: number) => {
  if (hasMemberOrgAccess && id) {
    const response = (await api.guestside.getMemberOrganizationAsync1(id)).data;

    if (response && response.parentId) {
      const subResponse = (await api.guestside.getMemberOrganizationAsync1(response.parentId)).data;

      if (subResponse.parentId) {
        return HierarchyLevel.LOCAL_OFFICE;
      }

      return HierarchyLevel.REGIONAL_OFFICE;
    }

    if (response && !response.parentId) {
      return HierarchyLevel.MAIN_OFFICE;
    }
  }

  return HierarchyLevel.MAIN_OFFICE;
};
