
import { api } from "@/api/api";
import { ApiGetCourseParticipantOfferletterDto } from "@/api/generated/Api";
import CourseViewFileModal from "@/components/course/details/files/CourseViewFileModal.vue";
import CourseGenerateOfferLetterModal from "@/components/course/details/offerLetter/CourseGenerateOfferLetterModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { featureFlags } from "@/featureFlags";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { FilePreviewData } from "@/shared/helpers/fileViewHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { capitalize } from "@/shared/helpers/textHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseOfferLetters",
  components: {
    BaseTableFiltered,
    CourseGenerateOfferLetterModal,
    BaseModal,
    CourseViewFileModal,
    BaseConfirmModalForm,
  },

  setup(_, { emit }) {
    const offerLetters = ref<ApiGetCourseParticipantOfferletterDto[]>([]);
    const search = ref("");
    const route = useRoute();
    const hasSelectedUsers = ref(false);
    const selectedParticipants = ref<any[]>([]);
    const store = useStore<StoreState>();
    const isSingleUser = ref(!!route.params.participantId);
    const fetchLoading = ref(false);
    const modalData = ref(getInitialModalData());
    const sendOfferLetterModalData = ref(getInitialModalData());
    const filePreviewModalData = ref(getInitialModalData());
    const cancelModalData = ref(getInitialModalData());

    const showFilePreviewModal = useOpenModal(ModalType.Unspecified, "", filePreviewModalData);
    const showCancelModal = useOpenModal(ModalType.Unspecified, "", cancelModalData);
    const showCreateOfferLetter = useOpenModal(ModalType.Add, "Tilbudsbrev", modalData);
    const openSendOfferLetter = useOpenModal(ModalType.Unspecified, "Send tilbudsbrev", modalData);

    enum cantCancellSignatureStatuses {
      SIGNEDVALUE = "signed",
      DOWNLOADEDVALUE = "downloaded",
      DOWNLOAD_FAILEDVALUE = "download_failed",
      CANCELLEDVALUE = "cancelled",
    }

    const cancelSigning = async () => {
      const signingFileId = cancelModalData.value.existingItem.signingFileId;

      if (!signingFileId) {
        return;
      }

      const payload = {
        signingFileIds: [
          {
            signingFileId,
            isValidated: true,
          },
        ],
      };

      try {
        await api.signature.cancelSigningCourseAdminBulk(payload);
        openNotification(store, NotificationItemType.Success, "Kansellering fullført");
        refetchOfferLetters();
        emit("close");
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data.errors) {
          error.response.data.errors.forEach((e: any): void => {
            openNotification(store, NotificationItemType.Error, e.errorMsg);
          });
        }
      }
    };

    const getOfferLetters = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const response = await api.offerletter.getOfferLetterParticipants(+route.params.id);
        if (!response.data.courseParticipants) {
          return;
        }

        if (isSingleUser.value) {
          offerLetters.value = response.data.courseParticipants.filter((x) => x.userId === +route.params.participantId);
        }

        if (!isSingleUser.value) {
          offerLetters.value = response.data.courseParticipants;
        }
        emit("close");
      });
    };

    const refetchOfferLetters = () => {
      offerLetters.value = [];
      fetchLoading.value = true;

      setTimeout(() => {
        getOfferLetters();
        fetchLoading.value = false;
      }, 5000);
    };

    const sendOfferLetter = async () => {
      const participantIds = selectedParticipants.value.map((x) => x.userId);
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          await api.offerletter.sendOfferlettersToSigning(+route.params.id, { userIds: participantIds });
          openNotification(store, NotificationItemType.Success, "Tilbudsbrevet er sendt");
          offerLetters.value = [];
          getOfferLetters();
        } catch (error: any) {
          if (error.response && error.response.data && error.response.data.errors) {
            error.response.data.errors.forEach((e: any): void => {
              openNotification(store, NotificationItemType.Error, e.errorMsg);
            });
          } else {
            openNotification(store, NotificationItemType.Error, "An error occurred while sending offer letters.");
          }
        }
      });
    };

    onMounted(() => {
      getOfferLetters();
    });

    const headers = [
      {
        text: "Student navn",
        value: "userFullName",
      },
      {
        text: "Handlinger",
        value: "actions",
      },
      {
        text: "Status",
        value: "generationStatus",
      },
      {
        text: "Signeringsstatus",
        value: "signatureStatus",
      },
      {
        text: "Tilbudsfrist",
        value: "offerDeadline",
      },
      {
        text: "Mangler fødselsdato",
        value: "missingBirthDate",
      },
    ];

    const checkHasSelectedUsers = () => {
      if (selectedParticipants.value.length > 0) {
        hasSelectedUsers.value = true;
      } else if (selectedParticipants.value.length === 0) {
        hasSelectedUsers.value = false;
      }
    };

    const getFilePreviewData = (file: ApiGetCourseParticipantOfferletterDto): FilePreviewData => ({
      id: file.fileId || 0,
      name: "Tilbudsbrev",
      mimeType: "pdf",
    });

    return {
      openSendOfferLetter,
      checkHasSelectedUsers,
      offerLetters,
      headers,
      search,
      modalData,
      showCreateOfferLetter,
      capitalize,
      sendOfferLetterModalData,
      sendOfferLetter,
      selectedParticipants,
      hasSelectedUsers,
      filePreviewModalData,
      getFilePreviewData,
      showFilePreviewModal,
      showCancelModal,
      cancelSigning,
      cancelModalData,
      getOfferLetters,
      refetchOfferLetters,
      fetchLoading,
      featureFlags,
      cantCancellSignatureStatuses,
    };
  },
});
