export enum DocumentSigningStatus {
  OrdinaryDocument = "ordinært document",
  SentToSigning = "sendt til signering",
  Unsigned = "usignert", // TODO check if this is exposed to minside
  SignedByResource = "signert av ressurs",
  SigningComplete = "signering fullført",
  Expired = "utløpt",
  Failed = "feilet",
  NotSent = "ikke sendt", // TODO check if this is exposed to minside
  Rejected = "avslått",
}
