
import { api } from "@/api/api";
import { ApiGetCourseRelatedFileDto } from "@/api/generated/Api";
import CourseAddFileModal from "@/components/course/details/files/CourseAddFileModal.vue";
import CourseChangeSigningStatusModal from "@/components/course/details/files/CourseChangeSigningStatusModal.vue";
import CourseViewFileModal from "@/components/course/details/files/CourseViewFileModal.vue";
import SendDocumentToSigningModal from "@/components/course/details/files/SendDocumentToSigningModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import { FileCategoryType } from "@/shared/enums/FileCategoryType.enum";
import { DocumentSigningStatus } from "@/shared/enums/documentSigningStatus.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { FilePreviewData, FileViewModel } from "@/shared/helpers/fileViewHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";

export interface CourseFilesWithSigning extends FileViewModel {
  signingStatus: string;
  signingDeadline: string;
}

export default defineComponent({
  name: "ParticipantDocuments",
  components: {
    BaseTableFiltered,
    CourseAddFileModal,
    BaseModal,
    BaseTooltipIconButton,
    BaseConfirmModalForm,
    CourseViewFileModal,
    CourseChangeSigningStatusModal,
    SendDocumentToSigningModal,
  },
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();
    const course = computed(() => store.state.courses.course);
    const courseFiles = ref<ApiGetCourseRelatedFileDto[]>();
    const newFileModalData = ref(getInitialModalData());
    const changeStatusModalData = ref(getInitialModalData());
    const deleteModalData = ref(getInitialModalData());
    const filePreviewModalData = ref(getInitialModalData());
    const sendToSigningModalData = ref(getInitialModalData());
    const search = ref<string>();
    const selectedUserId = computed(() => +route.params.participantId);
    const courseId = computed(() => +route.params.id);
    const isCourseDone =
      course.value.status === CourseStatus.Closed || course.value.status === CourseStatus.TeachingGrantMustBeCalculated;

    const showCreateFileModal = useOpenModal(ModalType.Add, "fil", newFileModalData);
    const showNewStatusModal = useOpenModal(ModalType.Unspecified, "Endre signeringsstaus", changeStatusModalData);
    const showDeleteFileModal = useOpenModal(ModalType.Delete, "fil", deleteModalData);
    const showFilePreviewModal = useOpenModal(ModalType.Unspecified, "", filePreviewModalData);
    const showSendToSigningModal = useOpenModal(ModalType.Unspecified, "", sendToSigningModalData);

    const submitCreateFileModal = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await fetchCourseFiles();
        newFileModalData.value.showModal = false;
      });
    };

    const submitChangeSigningStatus = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await fetchCourseFiles();
        changeStatusModalData.value.showModal = false;
      });
    };

    const fetchCourseFiles = async () => {
      const courseFilesResponse = (await api.file.getCourseFilesAsync(courseId.value)).data;

      courseFiles.value = courseFilesResponse
        .filter(
          (courseFile) =>
            courseFile.category !== FileCategoryType.Regulation &&
            courseFile.fileOwners?.some((owner) => owner.ownerId === selectedUserId.value)
        )
        .map((courseFile) => ({
          ...courseFile,
          insertedByFullName: courseFile.insertedByUserFullName ?? "",
          unsignedFileId: 0,
          updatedts: "",
          updated: "",
        }));
    };

    const deleteFile = async (item: ApiGetCourseRelatedFileDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          if (!item.id) {
            return;
          }
          await api.file.deleteFileAsync(item.id);
        } catch {
          openNotification(
            store,
            NotificationItemType.Error,
            `En feil oppsto ved sletting av ${item.originalFileName}.`
          );
        } finally {
          deleteModalData.value.showModal = false;
          await fetchCourseFiles();
        }
      });
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await fetchCourseFiles();
      });
    });

    return {
      courseId,
      selectedUserId,
      courseFiles,
      headers,
      validateNotEmpty,
      deleteFile,
      submitCreateFileModal,
      submitChangeSigningStatus,
      showCreateFileModal,
      showNewStatusModal,
      showDeleteFileModal,
      showFilePreviewModal,
      showSendToSigningModal,
      search,
      newFileModalData,
      changeStatusModalData,
      deleteModalData,
      filePreviewModalData,
      sendToSigningModalData,
      formatDateTime,
      isCourseDone,
      getFilePreviewData,
      DocumentSigningStatus,
    };
  },
});

const headers = [
  {
    text: "Filnavn",
    value: "originalFileName",
  },
  {
    text: "Handlinger",
    value: "actions",
    sortable: false,
  },
  {
    text: "Kategori",
    value: "category",
  },
  {
    text: "Beskrivelse",
    value: "description",
  },
  {
    text: "Opplastningsdato",
    value: "inserted",
  },
  {
    text: "Lastet opp av",
    value: "insertedByFullName",
  },
];

const getFilePreviewData = (file: ApiGetCourseRelatedFileDto): FilePreviewData => ({
  id: file.id || 0,
  name: file.originalFileName,
  mimeType: file.mimeType,
});
