var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.filePreviewModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1500
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      },
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return function () {
          return _vm.filePreviewModalData.showModal = false;
        }.apply(null, arguments);
      }
    }
  }, [_c('CourseViewFileModal', {
    attrs: {
      "file": _vm.getFilePreviewData(_vm.filePreviewModalData.existingItem)
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      }
    }
  })], 1) : _vm._e(), _vm.modalData.showModal ? _c('BaseModal', [_c('CourseGenerateOfferLetterModal', {
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      },
      "getOfferLetters": _vm.refetchOfferLetters
    }
  })], 1) : _vm._e(), _vm.cancelModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.cancelModalData
    },
    on: {
      "submit": _vm.cancelSigning,
      "close": function close($event) {
        _vm.cancelModalData.showModal = false;
      }
    }
  }, [_vm._v(" Er du sikker på at du vil avbryte signeringen av tilbudsbrevet? ")])], 1) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "padding": "0 20px"
    }
  }, [_c('v-text-field', {
    staticClass: "mr-5 py-5",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Søk i rader",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "color": "primary",
      "data-cy": "addFile"
    },
    on: {
      "click": _vm.getOfferLetters
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-refresh")]), _vm._v(" Oppdater ")], 1), _c('v-btn', {
    attrs: {
      "color": "primary",
      "data-cy": "addFile"
    },
    on: {
      "click": _vm.showCreateOfferLetter
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Lag tilbudsbrev ")], 1), _c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "color": "primary",
      "disabled": !_vm.hasSelectedUsers
    },
    on: {
      "click": _vm.sendOfferLetter
    }
  }, [_c('span', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-email")]), _vm._v(" Send ")], 1)])], 1), [_c('BaseTableFiltered', _vm._g({
    attrs: {
      "headers": _vm.headers,
      "items": _vm.offerLetters,
      "search": _vm.search,
      "item-key": "userId",
      "no-data-text": "Ingen filer",
      "show-select": "",
      "loading": _vm.fetchLoading
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.fileId ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.showFilePreviewModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-eye ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis dokument")])]) : _vm._e(), item.fileId && !Object.values(_vm.cantCancellSignatureStatuses).includes(item.signatureStatus) ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([item.isCancellable ? {
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.showCancelModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-close ")])];
            }
          } : null], null, true)
        }, [_c('span', [_vm._v("Avbryt signering")])]) : _vm._e()];
      }
    }, {
      key: "item.offerDeadline",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(value ? value : "-") + " ")];
      }
    }, {
      key: "item.generationStatus",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(_vm.capitalize(value)) + " ")];
      }
    }, {
      key: "item.signatureStatus",
      fn: function fn(_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(_vm.capitalize(value)) + " ")];
      }
    }, {
      key: "item.missingBirthDate",
      fn: function fn(_ref7) {
        var value = _ref7.value;
        return [_vm._v(" " + _vm._s(value ? "Ja" : "Nei") + " ")];
      }
    }], null, true),
    model: {
      value: _vm.selectedParticipants,
      callback: function callback($$v) {
        _vm.selectedParticipants = $$v;
      },
      expression: "selectedParticipants"
    }
  }, _vm.checkHasSelectedUsers()))]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }