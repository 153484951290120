var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.type,
      "editable": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": "",
      "disabled": _vm.isCourseDone
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.canEditName ? _c('PersonSearchInput', {
    attrs: {
      "label": "Legg til ".concat(_vm.isVocationalSchool ? 'student' : 'deltaker'),
      "filterIsActive": ""
    },
    model: {
      value: _vm.applicantData.userId,
      callback: function callback($$v) {
        _vm.$set(_vm.applicantData, "userId", $$v);
      },
      expression: "applicantData.userId"
    }
  }) : _vm._e()], 1)], 1), _c('v-row', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('TheMemberOrganizaionModalTreeHierarchy', {
    attrs: {
      "buttonLabel": "Velg medlemsorganisasjon",
      "memberOrgHierarchi": _vm.entireMemberOrgHierarchi
    },
    on: {
      "update": _vm.setMemberOrg
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Valgte medlemsorganisasjon",
      "readonly": "",
      "items": _vm.chosenMemberOrgs,
      "item-text": "name",
      "item-value": "id"
    },
    model: {
      value: _vm.applicantData.memberOrganizationId,
      callback: function callback($$v) {
        _vm.$set(_vm.applicantData, "memberOrganizationId", $$v);
      },
      expression: "applicantData.memberOrganizationId"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.isDisabled ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-select', {
          attrs: {
            "disabled": _vm.isDisabled,
            "label": "Status",
            "items": _vm.courseParticipantStatuses,
            "item-text": "text",
            "item-value": "value",
            "rules": _vm.validateNotEmpty,
            "data-cy": "editParticipantStatus"
          },
          model: {
            value: _vm.applicantData.status,
            callback: function callback($$v) {
              _vm.$set(_vm.applicantData, "status", $$v);
            },
            expression: "applicantData.status"
          }
        })], 1)];
      }
    }], null, false, 1676112803)
  }, [_c('span', [_vm._v("Du kan ikke endre status på deltakeren fordi deltaker har status " + _vm._s(_vm.applicantData.status) + ". Kontakt AOF for å forandre status på deltakeren")])]) : _vm._e(), !_vm.isDisabled ? _c('v-select', {
    attrs: {
      "disabled": _vm.isDisabled,
      "label": "Status",
      "items": _vm.courseParticipantStatuses,
      "item-text": "text",
      "item-value": "value",
      "rules": _vm.validateNotEmpty,
      "data-cy": "editParticipantStatus"
    },
    model: {
      value: _vm.applicantData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.applicantData, "status", $$v);
      },
      expression: "applicantData.status"
    }
  }) : _vm._e()], 1), _vm.participantComments.length > 0 ? _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('ChatLog', {
    attrs: {
      "comments": _vm.participantComments,
      "currentUserId": +_vm.currentUserId,
      "guestUserVisibilityCollection": _vm.guestUserVisibilityCollection
    },
    on: {
      "updateGuestUserVisibilityCollection": _vm.updateGuestUserVisibilityCollection
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": "Legg til en kommentar",
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "data-cy": "editParticipantStatusComment"
    },
    model: {
      value: _vm.createdComment.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.createdComment, "comment", $$v);
      },
      expression: "createdComment.comment"
    }
  }), !_vm.hasMemberOrgAccess ? _c('v-switch', {
    attrs: {
      "label": "Synliggjør kommentar for gjestebruker",
      "dense": ""
    },
    model: {
      value: _vm.createdComment.isGuestUserVisible,
      callback: function callback($$v) {
        _vm.$set(_vm.createdComment, "isGuestUserVisible", $$v);
      },
      expression: "createdComment.isGuestUserVisible"
    }
  }) : _vm._e()], 1), !_vm.hasMemberOrgAccess ? _c('div', [_c('v-divider'), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', {
    attrs: {
      "value": false,
      "dense": "",
      "label": "Bruk ".concat(_vm.isVocationalSchool ? 'student' : 'deltaker', " som bestiller og fakturamottaker")
    },
    model: {
      value: _vm.useParticipantAsInvoice,
      callback: function callback($$v) {
        _vm.useParticipantAsInvoice = $$v;
      },
      expression: "useParticipantAsInvoice"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "disabled": _vm.useParticipantAsInvoice,
      "label": "Bestiller og fakturamottaker er like",
      "row": ""
    },
    model: {
      value: _vm.isInvoiceAndPurchaserEqual,
      callback: function callback($$v) {
        _vm.isInvoiceAndPurchaserEqual = $$v;
      },
      expression: "isInvoiceAndPurchaserEqual"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Ja",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Nei",
      "value": false
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "ml-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('CustomerOrganizationSearchInput', {
    attrs: {
      "existingId": _vm.applicantData.purchaserId || 0,
      "label": _vm.isInvoiceAndPurchaserEqual ? 'Bestiller og fakturamottaker' : 'Bestiller',
      "disable": _vm.useParticipantAsInvoice
    },
    on: {
      "updateId": _vm.updatePurchaserId
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('CustomerOrganizationSearchInput', {
    attrs: {
      "existingId": _vm.applicantData.invoiceRecipientId || 0,
      "label": "Fakturamottaker",
      "disable": _vm.useParticipantAsInvoice || _vm.isInvoiceAndPurchaserEqual
    },
    on: {
      "updateId": _vm.updateInvoiceRecipientId
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }