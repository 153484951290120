var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-title', _vm._g(_vm._b({
    staticClass: "pa-2 mb-2"
  }, 'v-card-title', _vm.$attrs, false), _vm.$listeners), [_vm._t("prefix"), _vm.icon ? _c('v-icon', {
    staticClass: "pr-4 py-0",
    attrs: {
      "large": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.icon) + " ")]) : _vm._e(), _c('span', {
    staticClass: "title font-weight-light text-center d-flex",
    class: {
      headerColor: _vm.usePrimaryColor
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _vm.content && !_vm.displayText ? _c('v-icon', {
    staticClass: "ml-auto"
  }, [_vm._v(" mdi-chevron-down ")]) : _vm._e(), _vm.content && _vm.displayText ? _c('v-icon', {
    staticClass: "ml-auto"
  }, [_vm._v(" mdi-chevron-up ")]) : _vm._e()], 1), _c('v-spacer'), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }