var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Personlig informasjon"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.hasMemberOrgAccess ? _vm.contact.firstName : _vm.contact.customer.firstName,
      "label": "Fornavn"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.hasMemberOrgAccess ? _vm.contact.lastName : _vm.contact.customer.lastName,
      "label": "Etternavn"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.hasMemberOrgAccess ? _vm.contact.birthDate ? _vm.formatDate(_vm.contact.birthDate) : _vm.contact.birthYear : _vm.contact.customer.birthDate ? _vm.formatDate(_vm.contact.customer.birthDate) : _vm.contact.customer.birthYear,
      "label": "Fødselsdato"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.hasMemberOrgAccess ? _vm.getGender(_vm.contact.gender) : _vm.getGender(_vm.contact.customer.gender),
      "label": "Kjønn",
      "data-cy": "Gender"
    }
  })], 1)], 1), _c('BaseSheetHeader', {
    attrs: {
      "title": "Kontaktinformasjon"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.contact.email,
      "label": "E-post"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.contact.phoneNumber,
      "label": "Telefonnummer"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.contact.mobileNumber,
      "label": "Mobilnummer"
    }
  })], 1)], 1), _c('BaseSheetHeader', {
    attrs: {
      "title": "Adresseinformasjon"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.contact.postAddress1,
      "label": "Adresse"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.contact.postZip,
      "label": "Postnr",
      "data-cy": "Postnr"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.contact.postCity,
      "label": "Poststed"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.contact.postCountry,
      "label": "Land"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }