var render = function () {
  var _vm$course;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showEditContactInformationModal ? _c('BaseModal', {
    on: {
      "close": _vm.closeModal
    }
  }, [_c('EditContactInformationModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "existingItemModal": _vm.modalData.existingItem,
      "existingItemId": _vm.modalData.existingItemId,
      "elementId": _vm.modalData.existingItemId,
      "defaultValues": _vm.modalData.defaultValues
    },
    on: {
      "close": _vm.closeModal
    }
  })], 1) : _vm._e(), _vm.showEditParticipantInfoModal ? _c('BaseModal', {
    on: {
      "close": _vm.closeModal
    }
  }, [_c('UpsertParticipantModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "type": _vm.modalData.modalType,
      "memberOrganizations": _vm.orderedMemberOrganizations,
      "courseDefaultOptions": _vm.courseDefaultOptions,
      "activeUsers": _vm.activeUsers,
      "selectedUserId": _vm.selectedUserId,
      "currentCourseId": _vm.currentCourseId,
      "active-feature-settings": _vm.activeFeatureSettings,
      "chosenMemberOrgId": _vm.chosenMemberOrgId,
      "subOrganizerId": ((_vm$course = _vm.course) === null || _vm$course === void 0 ? void 0 : _vm$course.subOrganizerOrganizationId) || 0
    },
    on: {
      "close": _vm.closeModal
    }
  })], 1) : _vm._e(), _vm.getCourse ? _c('BaseLayout', {
    staticClass: "px-0",
    attrs: {
      "displayTabs": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToParticipants
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(_vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.getCourse.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "subheader",
      fn: function fn() {
        var _vm$contact;

        return [_vm._v(_vm._s(((_vm$contact = _vm.contact) === null || _vm$contact === void 0 ? void 0 : _vm$contact.customer) && "".concat(_vm.contact.customer.firstName, " ").concat(_vm.contact.customer.lastName).concat(_vm.status ? " - ".concat(_vm.status) : "")))];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function fn() {
        return [_c('v-tabs', {
          attrs: {
            "background-color": "primary",
            "dark": ""
          },
          model: {
            value: _vm.activeTab,
            callback: function callback($$v) {
              _vm.activeTab = $$v;
            },
            expression: "activeTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#".concat(_vm.CourseParticipantTabNames.Personal)
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-account ")]), _c('span', {
          attrs: {
            "data-cy": "tabTitlePersonalInfo"
          }
        }, [_vm._v("Personlig informasjon")])], 1), _vm.userAppliedViaForm ? _c('v-tab', {
          attrs: {
            "href": "#".concat(_vm.CourseParticipantTabNames.Appform)
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-file-document-edit-outline ")]), _c('span', {
          attrs: {
            "data-cy": "tabAppform"
          }
        }, [_vm._v("Påmeldingsskjema")])], 1) : _vm._e(), _vm.getCourse.plan.registerHours ? _c('v-tab', {
          attrs: {
            "href": "#".concat(_vm.CourseParticipantTabNames.Attendence)
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-account-check-outline ")]), _c('span', {
          attrs: {
            "data-cy": "tabAttendance"
          }
        }, [_vm._v("Fremmøte")])], 1) : _vm._e(), !_vm.hasMemberOrgAccess ? _c('v-tab', {
          attrs: {
            "href": "#".concat(_vm.CourseParticipantTabNames.Documents)
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-file-document-multiple-outline")]), _c('span', {
          attrs: {
            "data-cy": "tabDocuments"
          }
        }, [_vm._v("Dokumenter")])], 1) : _vm._e(), _vm.isVocationalSchool ? _c('v-tab', {
          attrs: {
            "href": "#offerletter"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-email")]), _vm._v(" Tilbudsbrev ")], 1) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "ml-auto mr-5",
          attrs: {
            "color": "white",
            "outlined": "",
            "disabled": !_vm.canEditParticpantCheck
          },
          on: {
            "click": _vm._openEditParticipantInfo
          }
        }, [_c('span', [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v(" Rediger ")], 1)]), !_vm.hasMemberOrgAccess ? _c('v-btn', {
          staticClass: "ml-auto",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm._openEditPersonContactInfo();
            }
          }
        }, [_c('span', [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v(" Rediger profil ")], 1)]) : _vm._e()];
      },
      proxy: true
    }], null, false, 240209667)
  }, [[_c('v-tabs-items', {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": _vm.CourseParticipantTabNames.Personal,
      "data-cy": "tabItemPersonalInfo"
    }
  }, [_vm.contact ? _c('ParticipantGeneralInfo', {
    attrs: {
      "contact": _vm.contact
    }
  }) : _vm._e()], 1), _vm.userAppliedViaForm ? _c('v-tab-item', {
    attrs: {
      "value": _vm.CourseParticipantTabNames.Appform,
      "data-cy": "tabItemAppform"
    }
  }, [_vm.showParticipantWebApplication ? _c('ParticipantWebApplication', {
    attrs: {
      "contact": _vm.contact,
      "applicationInfo": _vm.applicationInfo,
      "applicationForm": _vm.applicationForm
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-tab-item', {
    attrs: {
      "value": _vm.CourseParticipantTabNames.Attendence,
      "data-cy": "tabItemAttendance"
    }
  }, [_c('ParticipantAttendance')], 1), _c('v-tab-item', {
    attrs: {
      "value": _vm.CourseParticipantTabNames.Documents,
      "data-cy": "tabItemDocuments"
    }
  }, [_c('ParticipantDocuments')], 1), _c('v-tab-item', {
    attrs: {
      "value": _vm.CourseParticipantTabNames.SigningDocuments,
      "data-cy": "tabItemDocuments"
    }
  }, [_c('ParticipantSigningDocuments')], 1), _c('v-tab-item', {
    attrs: {
      "value": "offerletter"
    }
  }, [_c('CourseOfferLetters')], 1)], 1)]], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }