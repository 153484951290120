
import {
  ApiGetApplicationFormResponseDto,
  ApiGetApplicationFormTemplateDto,
  ApiGetCourseDefaultOptionDto,
  ApiGetCustomerDto,
  ApiGetCustomerPersonDto,
  ApiGetGuestEmployeewithMemberOrganizationDto,
  ApiGetKursAdminCourseDto,
  ApiGetMemberOrganizationFeatureSettingDto,
} from "@/api/generated/Api";
import ParticipantAttendance from "@/components/course/details/participant/ParticipantAttendance.vue";
import ParticipantGeneralInfo from "@/components/course/details/participant/ParticipantGeneralInfo.vue";
import ParticipantWebApplication from "@/components/course/details/participant/ParticipantWebApplication.vue";
import ParticipantDocuments from "@/components/course/details/participant/ParticipantDocuments.vue";
import { ActiveUser } from "@/components/course/details/ParticipantsTable.vue";
import UpsertParticipantModal from "@/components/course/details/UpsertParticipantModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import EditContactInformationModal from "@/pages/adressebok/EditContactInformationModal.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { hasMemberOrgAccess, useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { getCustomerDtoRef } from "@/shared/helpers/courseHelpers";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { getOrderedMemberOrganizations, MemberOrganization } from "@/shared/helpers/getters";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import { mapGetters } from "vuex";
import ParticipantSigningDocuments from "@/components/course/details/participant/ParticipantSigningDocuments.vue";
import CourseOfferLetters from "./CourseFile/CourseOfferLetters.vue";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { featureFlags } from "@/featureFlags";
import isBefore from "date-fns/isBefore";
import { api } from "@/api/api";

export enum CourseParticipantTabNames {
  Personal = "personal",
  Appform = "appform",
  Attendence = "attendence",
  Documents = "documents",
  SigningDocuments = "signingDocuments",
}

export default defineComponent({
  name: "CourseParticipantDetailPage",
  components: {
    BaseLayout,
    ParticipantGeneralInfo,
    ParticipantWebApplication,
    ParticipantDocuments,
    ParticipantAttendance,
    BaseModal,
    UpsertParticipantModal,
    EditContactInformationModal,
    ParticipantSigningDocuments,
    CourseOfferLetters,
  },
  computed: {
    ...mapGetters("courses", ["getCourse"]),
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const restrictedAccessApi = useRestrictedAccessApi();
    const contact = ref<ApiGetCustomerDto>();
    const applicationInfo = ref<ApiGetApplicationFormResponseDto>();
    const applicationForm = ref<ApiGetApplicationFormTemplateDto>();
    const store = useStore<StoreState>();
    const showEditParticipantInfoModal = ref(false);
    const showEditContactInformationModal = ref(false);
    const courseDefaultOptions = ref<ApiGetCourseDefaultOptionDto>({});
    const contacts = ref<(ApiGetCustomerDto | ApiGetCustomerPersonDto)[]>([]);
    const selectedUserId = ref(+route.params.participantId);
    const currentCourseId = ref(+route.params.id);
    const orderedMemberOrganizations = ref<MemberOrganization[]>();
    const { status } = store.state.courseParticipants.person;
    const canEditParticpantCheck = ref<boolean>(false);
    const userBypassPermission = ref<boolean>(false);
    const currentGuestUser = ref<ApiGetGuestEmployeewithMemberOrganizationDto>();
    const activeFeatureSettings = ref<ApiGetMemberOrganizationFeatureSettingDto[]>([]);
    const modalData = ref<ModalBaseData>(getInitialModalData());
    const course = ref<ApiGetKursAdminCourseDto>();
    const userAppliedViaForm = route.query.appliedViaForm === "true";
    const activeTab = ref(route.query.tab || CourseParticipantTabNames.Personal);
    const chosenMemberOrgId = ref<number>(0);

    watch(activeTab, () => {
      router.replace({ query: { tab: activeTab.value } });
    });

    const getActiveFeatureSettings = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (hasMemberOrgAccess) {
          activeFeatureSettings.value = (await api.guestside.getActivatedMemberOrganizationFeatureSettings1()).data;
        }

        if (!hasMemberOrgAccess) {
          activeFeatureSettings.value = (await api.organization.getActivatedMemberOrganizationFeatureSettings()).data;
        }
      });
    };

    const getCurrentUser = async () => {
      if (!hasMemberOrgAccess) {
        return;
      }

      currentGuestUser.value = (await api.guestside.getGuestUserCurrentGuestEmployeeAsync()).data;
      let topLevelId = currentGuestUser.value?.memberOrganization?.id || 0;

      // This mess is to find the topLevel in the memberOrgHierarchy, ggwp backend
      if (currentGuestUser.value) {
        if (currentGuestUser.value?.memberOrganization) {
          if (currentGuestUser.value.memberOrganization.parentId) {
            // fetch org based on guestUserMemberOrgId
            const parentOrg = (
              await api.guestside.getMemberOrganizationAsync1(currentGuestUser.value.memberOrganization.parentId)
            ).data;

            topLevelId = currentGuestUser.value.memberOrganization.parentId;

            if (parentOrg.parentId) {
              const parentParentOrg = (await api.guestside.getMemberOrganizationAsync1(parentOrg.parentId)).data;
              topLevelId = parentOrg.parentId;

              if (parentParentOrg.parentId) {
                topLevelId = parentParentOrg.parentId;
              }
            }
          }

          checkGuestUserTopLevelMemberOrganizationFeaturesSettings(topLevelId);
        }
      }
    };

    const canEditParticipant = async function () {
      if (!hasMemberOrgAccess) {
        canEditParticpantCheck.value = true;
        return;
      }

      if (hasMemberOrgAccess && userBypassPermission.value) {
        canEditParticpantCheck.value = true;
        return;
      }

      if (!course.value?.enrollmentDeadline) {
        return;
      }

      if (isBefore(new Date(course.value.enrollmentDeadline), new Date())) {
        canEditParticpantCheck.value = true;
      }
    };

    const checkGuestUserTopLevelMemberOrganizationFeaturesSettings = async (topLevelId: number) => {
      if (!hasMemberOrgAccess) {
        return;
      }

      if (!topLevelId) {
        return;
      }

      if (topLevelId === course?.value?.organizerOrganizationId) {
        if (checkFeatureSettings(topLevelId)) {
          userBypassPermission.value = true;
          canEditParticipant();
        }
      }
    };

    const checkFeatureSettings = (id: number) => {
      if (!id) {
        return false;
      }

      const memberOrganization = activeFeatureSettings.value.find((x) => x.organizationId === id);

      if (memberOrganization && memberOrganization.bypassEnrollmentDeadlineInGuestPortal) {
        return true;
      }

      return false;
    };

    const closeModal = () => {
      showEditContactInformationModal.value = false;
      showEditParticipantInfoModal.value = false;
    };

    const _openEditParticipantInfo = () => {
      const openEditParticipantInfo = useOpenModal(ModalType.Edit, "Deltaker", modalData);
      openEditParticipantInfo();
      showEditParticipantInfoModal.value = true;
    };

    const _openEditPersonContactInfo = () => {
      const openEditPersonContactInfo = useOpenModal(ModalType.Edit, "kontaktinformasjon", modalData);
      openEditPersonContactInfo({ id: selectedUserId });
      showEditContactInformationModal.value = true;
    };

    const getDefaultOptions = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const defaultOptions = (await restrictedAccessApi.getDefaultOptions()).data;
        courseDefaultOptions.value = defaultOptions;
      });
    };

    const showParticipantWebApplication = computed(
      () => applicationInfo.value && applicationForm.value && contact.value
    );

    async function setApplicationData() {
      if (!userAppliedViaForm) {
        return;
      }
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          const appFormResponse = await restrictedAccessApi.getAppFormResponse(
            +route.params.id,
            +route.params.participantId
          );
          if (!appFormResponse.data) {
            return;
          }
          applicationInfo.value = appFormResponse.data;

          const appFormTemplateResponse = await restrictedAccessApi.getAppFormTemplate(
            applicationInfo.value?.templateId
          );
          if (!appFormTemplateResponse.data) {
            return;
          }
          applicationForm.value = appFormTemplateResponse.data;
        } catch {
          openNotification(store, NotificationItemType.Error, "Feil under lesing av application form");
        }
      });
    }

    const getCustomerPerson = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        contact.value = (await restrictedAccessApi.getCustomerPerson(+route.params.participantId)).data;
      });
    };

    const navigateToParticipants = () =>
      router.push({
        name: SingleCourseRouteNames.CourseParticipants,
      });

    const setOrderedMemberOrganizations = async () =>
      (orderedMemberOrganizations.value = await getOrderedMemberOrganizations());

    const activeUsers = computed(() => {
      const filteredActiveUsers = contacts.value.filter((currentUser) => currentUser.isActive);
      return filteredActiveUsers.reduce<ActiveUser[]>(
        (previous, current: ApiGetCustomerDto | ApiGetCustomerPersonDto) => {
          const currentRef = getCustomerDtoRef(current);
          const activeUser = {
            id: current.id,
            firstName: currentRef.firstName,
            lastName: currentRef.lastName,
            email: current.email,
            birthDate: formatDate(currentRef.birthDate),
          };
          return [...previous, activeUser];
        },
        []
      );
    });

    const getCourse = async () => {
      course.value = (await restrictedAccessApi.getCourse(+route.params.id)).data;
      canEditParticipant();
    };

    onMounted(async () => {
      await Promise.all([
        getCourse(),
        getCustomerPerson(),
        setApplicationData(),
        getDefaultOptions(),
        setOrderedMemberOrganizations(),
        getCurrentUser(),
        getActiveFeatureSettings(),
        canEditParticipant(),
        (chosenMemberOrgId.value = +route.query.participantMemberOrgId || 0),
      ]);
    });

    return {
      activeTab,
      contact,
      applicationInfo,
      applicationForm,
      userAppliedViaForm,
      showParticipantWebApplication,
      navigateToParticipants,
      CourseParticipantTabNames,
      orderedMemberOrganizations,
      closeModal,
      showEditContactInformationModal,
      showEditParticipantInfoModal,
      modalData,
      courseDefaultOptions,
      activeUsers,
      selectedUserId,
      currentCourseId,
      _openEditParticipantInfo,
      _openEditPersonContactInfo,
      hasMemberOrgAccess,
      status,
      isVocationalSchool: isVocationalSchool(store.state.plans.studyplan.mainCourseId),
      featureFlags,
      canEditParticpantCheck,
      activeFeatureSettings,
      course,
      chosenMemberOrgId,
    };
  },
});
