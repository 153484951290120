
import { api } from "@/api/api";
import {
  ApiCourseParticipantAttendanceBaseDto,
  ApiCourseParticipantAttendanceBaseDtoValidationResponseDto,
  ApiGetCourseParticipantAttendanceDto,
} from "@/api/generated/Api";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { hasMemberOrgAccess, useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import axios from "axios";
import isBefore from "date-fns/isBefore";
import CourseEditAttendanceModal from "../CourseEditAttendanceModal.vue";

export default defineComponent({
  name: "ParticipantAttendance",
  components: { BaseTableFiltered, BaseModal, CourseEditAttendanceModal },
  setup() {
    const route = useRoute();
    const store = useStore<StoreState>();
    const restrictedAccessApi = useRestrictedAccessApi();
    const search = ref("");
    const attendance = ref();
    const modalData = ref(getInitialModalData());
    const selectedAttendances = ref<ApiGetCourseParticipantAttendanceDto[]>();

    const getAttendance = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const courseParticipants = (
          await restrictedAccessApi.getParticipantAttendance(+route.params.id, {
            UserId: +route.params.participantId,
          })
        ).data;

        attendance.value = courseParticipants
          .map((attendanceEntry) => ({
            ...attendanceEntry,
            isSelectable: !attendanceEntry.isRegistered,
            scheduleHours: attendanceEntry.scheduleHours.toFixed(1),
          }))
          .filter((attendanceEntry) => isBefore(new Date(attendanceEntry.scheduleStart), new Date()));
      });
    };

    const updateCourseParticipantAttendance = async (attendances: ApiCourseParticipantAttendanceBaseDto[]) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          await api.course.batchMergeCourseParticipantAttendancesByCourseIdAsync(+route.params.id, { attendances });
          openNotification(store, NotificationItemType.Success, "Fremmøte er registrert");
          await getAttendance();
        } catch (error: unknown) {
          if (!axios.isAxiosError(error) || !error.response || !isValidationErrorResponse(error.response?.data)) {
            return;
          }
          const { validationList } = error.response?.data;
          validationList?.forEach((item) => {
            item.validationErrors?.forEach((message) => {
              openNotification(store, NotificationItemType.Warning, `Valideringsfeil: ${message}`);
            });
          });
        }
      });
    };

    const openAttendanceParticipantModal = useOpenModal(ModalType.Edit, "fremmøte", modalData);

    const registerSingleAttendance = async (attendanceEntry: ApiCourseParticipantAttendanceBaseDto) => {
      await updateCourseParticipantAttendance([attendanceEntry]);
    };

    const batchRegisterAttendance = async () => {
      if (!selectedAttendances.value) {
        return;
      }

      const batchRegisteredAttendances = selectedAttendances.value.map((participant) => ({
        ...participant,
        noOfHours: participant.scheduleHours,
      }));
      await updateCourseParticipantAttendance(batchRegisteredAttendances);
      selectedAttendances.value = [];
    };

    onMounted(() => getAttendance());

    return {
      search,
      headers: getHeaders(hasMemberOrgAccess),
      attendance,
      modalData,
      formatDateTime,
      selectedAttendances,
      batchRegisterAttendance,
      registerSingleAttendance,
      openAttendanceParticipantModal,
      showRegisterButton: computed(() => !hasMemberOrgAccess && !!selectedAttendances.value?.length),
      hasMemberOrgAccess,
    };
  },
});

const getHeaders = (hasMemberOrgAccess: boolean) => {
  const headers = [
    {
      text: "Navn",
      value: "userFullName",
    },
    {
      text: "Dato",
      value: "scheduleStart",
    },
    {
      text: "Fremmøte registrert",
      value: "noOfHours",
    },
    {
      text: "Antall timer i timeplan",
      value: "scheduleHours",
    },
    {
      text: "Status",
      value: "isRegistered",
    },
  ];

  if (!hasMemberOrgAccess) {
    headers.push({
      text: "Handlinger",
      value: "actions",
    });
  }

  return headers;
};

const isValidationErrorResponse = (
  response: unknown
): response is ApiCourseParticipantAttendanceBaseDtoValidationResponseDto =>
  typeof response === "object" &&
  response !== null &&
  ("validationList" in response || "generalValidationErrors" in response);
