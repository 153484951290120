var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._g(_vm._b({
    class: "px-".concat(_vm.dense ? 3 : 5, " py-").concat(_vm.dense ? 2 : 4, " d-flex align-").concat(_vm.alignment, " ").concat(_vm.fillHeight ? 'fill-height' : ''),
    attrs: {
      "flat": "",
      "to": _vm.to
    }
  }, 'v-card', Object.assign({
    color: _vm.light ? '' : 'grey lighten-4',
    outlined: _vm.light
  }, _vm.$attrs), false), _vm.eventHandlers), [_vm.icon ? _c('v-icon', {
    class: "mr-".concat(_vm.dense ? 2 : 4),
    attrs: {
      "large": !_vm.dense,
      "color": _vm.light ? 'grey lighten-1' : 'primary'
    }
  }, [_vm._v(" " + _vm._s(_vm.icon) + " ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "text-caption text--secondary",
    attrs: {
      "data-cy": "iconCardTitle-".concat(_vm.dataCy)
    }
  }, [_vm._v(" " + _vm._s(_vm.headline) + " ")]), _c('div', {
    class: {
      'body-2': _vm.light,
      'text-subtitle-2': _vm.dense,
      'text-h6': !_vm.light && !_vm.dense,
      'order-first': !_vm.captionAbove
    },
    attrs: {
      "data-cy": "iconCardValue-".concat(_vm.dataCy)
    }
  }, [_vm._v(" " + _vm._s(_vm.valueString) + " ")])]), _vm.prependIcon ? _c('v-spacer') : _vm._e(), _vm.prependIcon ? _c('v-icon', {
    class: "mr-".concat(_vm.dense ? 2 : 4),
    attrs: {
      "large": !_vm.dense,
      "color": _vm.light ? 'grey lighten-1' : 'primary',
      "data-cy": "prependIcon-".concat(_vm.dataCy)
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('clickedPrependIcon');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.prependIcon) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }