
import { api } from "@/api/api";
import { ApiUpsertCourseParticipantOfferletterCourseVariablesDto } from "@/api/generated/Api";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { CourseParticipantType } from "@/shared/enums/courseParticipantType.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import {
  TiptapVuetify, // @ts-ignore
} from "tiptap-vuetify";
import { tiptapExtensions } from "./tiptapExtensions";
import { authService } from "@/auth/authService";

export default defineComponent({
  name: "CourseGenerateOfferLetterModal",
  emits: ["close", "getOfferLetters"],
  components: { BaseModalForm, TiptapVuetify, BaseDatePicker },
  setup(_, { emit }) {
    const store = useStore<StoreState>();
    const organizerText =
      store.state.courses.course.ownerOrganizationId === 1042
        ? "Fagskolen AOF Norge § 2-3"
        : "AOF Østfold - Fagskolen § 2-3";

    const setInitialOfferLetter = (): ApiUpsertCourseParticipantOfferletterCourseVariablesDto => ({
      decisionText: `<p>
        Alt 1 Formell kompetanse / YRK: <br>
        Dermed har du som søker fylt kravet for opptak på bakgrunn av formell kompetanse i henhold til Forskrift om fagskoleutdanning ved ${organizerText} 
      </p>

      <p>
        Alt 2 Realkompetanse: <br>
        Vi har vurdert at du har realkompetanse som er likeverdig med det formelle opptakskravet i henhold til Forskrift om fagskoleutdanning ved ${organizerText}  
      </p>

      <p>
        Alt 3: <br>
        Denne studieplassen tilbys med betinget opptak. For å beholde
        studieplassen må du dokumentere bestått praktisk prøve (fagprøve) ) /bestått eksamener som gir
        yrkeskompetanse innen 6 måneder.Du har dokumentert at du med all sannsynlighet vil avlegge
        den praktiske fagprøven, ev. at eksamener som vil gi yrkeskompetanse skal avlegges innen 6
        måneder etter studiestart og tilfredsstiller å bli tatt opp til studiet med betinget opptak.
      </p>
`,
      showReference: true,
      ourReference: `${authService.user.given_name} ${authService.user.family_name}`,
      showPoliceCertificate: true,
      // Default date needs to be 14 days a head of time.
      offerDeadline: new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000).toISOString(),
      practicalHourAmount: 0,
    });

    const route = useRoute();
    const modalType = ref<ModalType>(ModalType.Add);
    const offerLetter = ref<ApiUpsertCourseParticipantOfferletterCourseVariablesDto>(setInitialOfferLetter());
    const participants = ref<any[]>([]);
    const selectedParticipant = ref<any>(null);
    const restrictedAccessApi = useRestrictedAccessApi();

    const getCourseParticipants = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const response = (
          await restrictedAccessApi.getCourseParticipants(+route.params.id, {
            RoleNames: [CourseParticipantType.Student],
            IsActive: true,
          })
        ).data;
        participants.value = response;
      });
    };

    const generateOfferLetter = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!route.params.participantId && selectedParticipant.value === null) {
          openNotification(store, NotificationItemType.Error, "Velg en student!");
          return;
        }
        await api.offerletter.upsertAndGenerateOfferLetter(
          +route.params.id,
          selectedParticipant.value ? selectedParticipant.value : +route.params.participantId,
          offerLetter.value
        );
        openNotification(store, NotificationItemType.Success, "Tilbudsbrev er sendt til generering");
        getCourseParticipants();

        if (!+route.params.participantId) {
          emit("getOfferLetters");
        }
        emit("close");
      });
    };

    onMounted(() => {
      getCourseParticipants();
    });

    return {
      generateOfferLetter,
      modalType,
      offerLetter,
      tiptapExtensions,
      participants,
      selectedParticipant,
      showSelectParticipant: "participantId" in route.params,
      validateNotEmpty,
    };
  },
});
